import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentoExternoService {

  constructor(public http: HttpClient) { }

  
  saveFile(documentoExterno: any){
    return this.http.post<HttpResponse<Object>>(environment.baseApi+"/api/private/documentoExternoRestService/upload",documentoExterno,{observe:'response'});
  }

}
