<app-breadcrumbs></app-breadcrumbs>
<button mat-button mat-raised-button class="bg-green" style="margin-left: 90%;margin-bottom: 10px" (click)="exportarPdf(dataSource)">Exportar pdf</button>
<div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="territorio">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Territorio</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio != 'TOTAL'  && row.tipoTerritorio !='PAIS' && (this.idProceso==1 || this.idProceso==2)">
                    <a fxFlexFill [routerLink]="this.rLink" [queryParams]="{ idProceso: this.idProceso,idTerritorio: row.idTerritorio,tipoTerritorio: row.tipoTerritorio}"
                        class="mat-row-link">{{row.territorio}}</a>
                </div>

                <div *ngIf="row.territorio != 'TOTAL' && row.tipoTerritorio !='JUNTA' && this.idProceso==3">
                    <a fxFlexFill [routerLink]="this.rLink" [queryParams]="{ idProceso: this.idProceso,idTerritorio: row.idTerritorio,tipoTerritorio: row.tipoTerritorio}"
                        class="mat-row-link">{{row.territorio}}</a>
                </div>
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.territorio}}
                </div>
                <div *ngIf="row.tipoTerritorio =='JUNTA' && this.idProceso==3">
                    {{row.territorio}}
                </div>
                <div *ngIf="row.tipoTerritorio =='PAIS' && (this.idProceso==1 || this.idProceso==2)">
                    {{row.territorio}}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalActas">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Actas</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.totalActas}}
                </div>
                <div *ngIf="row.territorio != 'TOTAL'">
                    {{row.totalActas}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actasAprobadas">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Actas Aprobadas</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.actasAprobadas}}
                </div>
                <div *ngIf="row.territorio != 'TOTAL'">
                    {{row.actasAprobadas}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="porcentaje">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Porcentaje</mat-header-cell>
            <mat-cell *matCellDef="let row">
                    <div class="progress" style="width: 100px">
                            <div class="progress-bar" role="progressbar" style="width: {{row.porcentaje}}%;" aria-valuenow="{{row.porcentaje}}" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                    <!-- <mat-progress-bar mode="determinate" [value]="row.porcentaje"></mat-progress-bar> -->
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold;width: 100px">
                    {{row.porcentaje}}%
                </div>
                <div *ngIf="row.territorio != 'TOTAL'" style="width: 100px">
                    {{row.porcentaje}}%
                </div>
                
                
            </mat-cell>
        </ng-container>
           
        <ng-container matColumnDef="noIniciado">
            <mat-header-cell *matHeaderCellDef mat-sort-header>No Iniciado</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.noIniciado}}
                </div>
                <div *ngIf="row.territorio != 'TOTAL'">
                    {{row.noIniciado}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enProceso">
            <mat-header-cell *matHeaderCellDef mat-sort-header>En Proceso</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.enProceso}}
                </div>
                <div *ngIf="row.territorio != 'TOTAL'">
                    {{row.enProceso}}
                </div>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="atrasado">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Atrasado</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.territorio == 'TOTAL'" style="font-weight: bold">
                    {{row.atrasado}}
                </div>
                <div *ngIf="row.territorio != 'TOTAL'">
                    {{row.atrasado}}
                </div>
            </mat-cell>

        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="length == 0" class="noresults">No se encontraron resultados</div>
    <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons>
    </mat-paginator>
</div>
