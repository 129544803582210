import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TerritorioService } from 'src/app/services/territorio.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TipoNivel } from '../../../component/enums/tipo-nivel.enum';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-crea-zona',
  templateUrl: './crea-zona.component.html',
  styleUrls: ['./crea-zona.component.css']
})
export class CreaZonaComponent implements OnInit {
  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  public data: any;
  public dtTimesZones: any;
  public itemZona:any;
  constructor(@Inject(MAT_DIALOG_DATA) public mData: { data: any }, private territorioService:TerritorioService,
  public dialogRef: MatDialogRef<CreaZonaComponent>, private utils: UtilsService) { 

    this.data = mData.data;
  }

  public zonas = new FormGroup({
    zona:  new FormControl('', [Validators.required])
  });

  get zonasForm() {
    return this.zonas.controls;
  }

  ngOnInit(): void {
    setTimeout( () =>{
      this.getTimeZones();
    },500);
  }

  getTimeZones(){
    this.territorioService.getTerritorioTimezones().subscribe(resp =>{
      if (resp!=null && resp.body['body']!=null && resp.body['body']['response']){
        
        this.dtTimesZones= resp.body['body']['data'];
      
        this.getZone();
       
      }
    })
  }
  
  getZone() {
    if (this.data){
    Object.keys(this.dtTimesZones).forEach(key => {
      if (this.dtTimesZones[key] == this.data.zonaHoraria) {
        this.itemZona = this.dtTimesZones[key];
      }
    });
  }
  }

  getTimezone(terrStr:string){
    let indexPar: number;
      indexPar=terrStr.indexOf("+");
      if (indexPar>-1){
        return terrStr.substring(4) ;
      }else{
        indexPar=terrStr.indexOf("-");
        if (indexPar>-1){
          return terrStr.substring(3) ;
        }else{
          // utc 0
          return 0;
        }
      }
      
  } 

  guardar() {
   // console.log("data22: " + JSON.stringify(this.zonasForm.rut.value));
    let territorioNuevo = {
      id: this.data.codLocal,
      nombre: null,
      descripcion: null,
      sigla: null,
      tipo: null,
      orden: null,
      icono: null,
      visible: null,
      latitud: null,
      longitud: null,
      enEleccion: true,
      tiempoAlerta: null,
      timeZone: this.getTimezone(this.itemZona)
    }
    /* if (this.event == "new") { */
     
      this.territorioService.updateTerritorio(territorioNuevo).subscribe(resp => {
        if (resp) {         
          
          this.dialogEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
        }
      })

  }
}
