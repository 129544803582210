import { VistaConsulComponent } from './../../pages/vista-consul/vista-consul.component';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Funcionalidades } from 'src/app/component/enums/funcionalidades.enum';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
 // @Input() public menuItems: any;
/*   mapMenu:any; */
  menuItems:any;
  mapMenu:any;

  constructor(public auth: AuthService,public utils: UtilsService) { }

  ngOnInit(): void {
   this.getMenus();
  }
  getMenus(){
    let authInfo = this.auth.getAuthInfo();
    this.menuItems = this.utils.getFuncionalidadesRoute(this.mapMenu);

if (authInfo!=null && authInfo!=undefined){
      Object.keys(this.menuItems).forEach(keyMenu => {
        Object.keys(this.menuItems[keyMenu].groupItems).forEach(keyItem => {
          if (authInfo['tokenInfo']['perfil']== 'administrador') {
            if (this.menuItems[keyMenu].groupItems[keyItem].fnCode!=Funcionalidades.RESUMEN_CONSUL){
              this.menuItems[keyMenu].groupItems[keyItem].enable=true;
            }
          }else if (authInfo['tokenInfo']['perfil']== 'invitado') {
            if (this.menuItems[keyMenu].groupItems[keyItem].fnCode!=Funcionalidades.RESUMEN_CONSUL && this.menuItems[keyMenu].groupItems[keyItem].fnCode!=Funcionalidades.USUARIOS
              && this.menuItems[keyMenu].groupItems[keyItem].fnCode!=Funcionalidades.ZONA){
              this.menuItems[keyMenu].groupItems[keyItem].enable=true;
            }
          }else{
            if (this.menuItems[keyMenu].groupItems[keyItem].fnCode==Funcionalidades.RESUMEN_CONSUL || this.menuItems[keyMenu].groupItems[keyItem].fnCode==Funcionalidades.HOME){
              this.menuItems[keyMenu].groupItems[keyItem].enable=true;
            }
          }
        })
      })
    }
  }

}