<div class="container-loader" *ngIf="utils.progressBar">
  <mat-progress-bar mode="indeterminate" color="warn" class="pbar"></mat-progress-bar>
</div>
<mat-sidenav-container class="example-container">
<mat-sidenav #sidenav mode="{{modeSideNav}}"
[fixedInViewport]="true"
           [fixedTopGap]="0"
           [fixedBottomGap]="0"
           
>
<div *ngIf="this.auth.isAuth()">
  <app-navigation></app-navigation>
</div>

</mat-sidenav>
<mat-sidenav-content>
  <div *ngIf="auth.isAuth()">
      <div fxLayout="row">
          <div fxFlex="75">
              <strong>
                  <h3 class="mat-h1 mat-headline">Recepción Datos Extranjero
                  </h3>
              </strong>
          </div>
      <!--     <div fxFlex="25" style="text-align:right">
              <button mat-button mat-raised-button class="bg-bluedef" (click)="cerrarSesion()">cerrar</button>
          </div> -->

          <div fxFlex="25" style="text-align:right">
                  <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones"
                    class="userinfo">
                    <mat-icon class="icoc">account_circle</mat-icon>
                     Usuario: <b>{{ this.auth.getAuthInfo()['tokenInfo']['nombre'] }}</b> 
                    <mat-icon class="icoc_dd">arrow_drop_down</mat-icon>
                  </button>
              </div>
      </div>
      <mat-toolbar color="warn">
          <mat-toolbar-row class="toolbar-custom">
              <button mat-button (click)="sidenav.toggle()">
                  <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
              </button>
              <span> <a mat-list-item routerLink="/home">
                  <img src="./assets/images/logo-servel.png">
              </a>
              </span>
              
                      <!-- <button mat-button mat-raised-button class="item-m"  class="bg-bluedef" (click)="cerrarSesion()">Cerrar Sesión</button> -->
                    
                    
              
          </mat-toolbar-row>
          <mat-menu #menu="matMenu">
                  <button mat-button class="item-m" (click)="cerrarSesion()">
                    <mat-icon>clear</mat-icon>
                    <span>Cerrar Sesión</span>
                  </button>
                </mat-menu>
      </mat-toolbar>
  </div>
   <div [ngClass]="{'container container-nowidth  body-padding' : auth.isAuth()}">
<!--       <app-breadcrumbs></app-breadcrumbs> -->
      <router-outlet></router-outlet>
   </div>
</mat-sidenav-content>
</mat-sidenav-container>
<!--  <div [ngxSummernote]="config"></div>  -->

