import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ActaService } from './../../services/acta.service';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.css']
})
export class CountDownComponent implements OnInit, OnDestroy {
  @Input() idProceso: number;
  @Input() codigoLocal: number;
  @Input() estado: string;
  @Input() estadoAlerta: string;
  @Input() minutesToDday: number;
  @Input() secondsToDday: number;
  @Input() hoursToDday: number;
  @Input() tiempoAlerta: number;
  @Output() public estadoAlertaOutput: EventEmitter<any> = new EventEmitter();
  private subscriptionTimer: Subscription;

  public timeDifference;
  public mensajeFueraPlazo: string = 'FUERA DE PLAZO';

  public constructor(public actaService: ActaService) {

  }

  private getData() {
    setTimeout(() => {
      this.getTimeDifference(); 
      this.subscriptionTimer = interval(60000)
      .subscribe(x => { 
        this.getTimeDifference(); 
      });
    },  this.secondsToDday * 1000);
  }

  public getTimeDifference() {

    if (this.estadoAlerta != "NO_INICIADO" && this.estadoAlerta != "ATRASADO") {
      if (this.hoursToDday >= 0) {
        this.estadoAlertaOutput.emit("iniciado");
        if (this.minutesToDday > 0) {
          this.minutesToDday = this.minutesToDday - 1
        } else {
          this.minutesToDday = 59;
          this.hoursToDday = this.hoursToDday - 1;
        }
      } else {
        var minutosInt = +this.minutesToDday;
        var horasInt = +this.hoursToDday;
        if (this.minutesToDday < 59) {
          this.minutesToDday = minutosInt + 1;
        } else {
          this.minutesToDday = 0;
          this.hoursToDday = horasInt + 1;
        }
      }
    }

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptionTimer != undefined) {
      this.subscriptionTimer.unsubscribe();
    }
  }

  showAlert() {

    if (this.hoursToDday != null && this.minutesToDday != null && this.secondsToDday != null) {
      return true;
    }
    return false;
  }

  setColor() {
    if (this.estadoAlerta != null && this.estadoAlerta != undefined) {
      return this.estadoAlerta.toString().toLowerCase();
    }
    return '';
  }
}
