export class VistaSupervisor {
    continente: string;
    pais: string;
    juntaElectoral: string;
    circunscripcionElectoral: string;
    localVotacion: string;
    mesa: string
    estado: string;
    fechaCumplimiento: string;
    codLocal: string;
}   