<div *ngIf="!verTerritorios">
  <h1 class="display-5 title-app">Usuarios</h1>
  <div *ngIf="dataSource">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
      <div fxFlex="85%">
        <mat-form-field class="full">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Búsqueda Avanzada">
        </mat-form-field>
      </div>
      <div fxFlex="10%" *ngIf="this.auth.getAuthInfo()['tokenInfo']['perfil']=='administrador'">
        <button mat-button mat-raised-button class="bg-blue phase noborder full" id="nuevo" (click)="crearItem()">Nuevo</button>
      </div>
    </div>
    <!--     <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="center">
    <h3 class="title-app mat-h1 mat-headline">
      Paises</h3>
    </div> -->
    <div class="mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="rut">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Rut</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.rut}}-{{row.rutDv}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.nombre}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pais">
            <mat-header-cell *matHeaderCellDef mat-sort-header>País</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.pais}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="circunscripcion">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Circunscripción</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.circunscripcion}} </mat-cell>
            </ng-container>

        <ng-container matColumnDef="habilitado">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Habilitado</mat-header-cell>
          <mat-cell *matCellDef="let row">{{muestraSiNo(row.habilitado)}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="perfil">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Perfil</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.perfil}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let val" data-label="Acciones">
            <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones" matTooltipPosition="right" *ngIf="this.auth.getAuthInfo()['tokenInfo']['perfil']=='administrador'">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="crearItem(val, 'edit')">
                <mat-icon>create</mat-icon>
                <span>Editar</span>
              </button>
              <div *ngIf="!val.administrador">
              <button mat-menu-item (click)="editarTerritorio(val, 'edit')">
                <mat-icon>create</mat-icon>
                <span>Territorios</span>
              </button>
            </div>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="length == 0" class="noresults">No se encontraron resultados</div>
      <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
<div *ngIf="verTerritorios">
<app-territorio-usuario [data]="data" #territorioUsuario></app-territorio-usuario>
</div>