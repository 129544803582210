import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { FiltrosTerritorialesComponent } from 'src/app/component/vista-administracion/filtros-territoriales/filtros-territoriales.component';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { UtilsService } from 'src/app/services/utils.service';
import { TerritorioService } from 'src/app/services/territorio.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crea-territorio-usuario',
  templateUrl: './crea-territorio-usuario.component.html',
  styleUrls: ['./crea-territorio-usuario.component.css']
})
export class CreaTerritorioUsuarioComponent implements OnInit {
  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild("filtroTerritorial") filtroTerritorialForm: FiltrosTerritorialesComponent;
  public data: any;
  public estado: boolean;
  public administrador: boolean;
  hide = true;
  public event: string;
  public dtTimesZones: any;
  public itemZona: any;
  public validaFiltro: boolean = true;
  public codigoLocal: number;
  public codigoCircunscripcion: number;

  constructor(private usuariosService: UsuariosService,
    public dialogRef: MatDialogRef<CreaTerritorioUsuarioComponent>, private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public mData: { data: any, nuevo: any }, private territorio: TerritorioService, private cdRef: ChangeDetectorRef) {
    this.data = mData.data;
    
    if (this.mData.nuevo) {
      this.event = "new";
    } else {
      this.codigoLocal = this.data.codLocal;
      this.event = "edit";
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getTimeZones();


    
      this.filtroTerritorialForm.validaFiltros.subscribe((resp) => {
      

        this.validaFiltro = resp;
      })

      this.filtroTerritorialForm.localVotacionOutput.subscribe((codigoLocal) => {
      
        this.codigoLocal = codigoLocal;
      })

      this.filtroTerritorialForm.circunscripcionOutput.subscribe((codigoCircunscripcion) => {
   
        this.codigoCircunscripcion = codigoCircunscripcion;
      })


    }, 500);
  }
  guardar() {
    let usuarioNuevo: any;
    if (this.data != undefined) {
      usuarioNuevo = {
        rutUsuario: this.data.rut,
        rutDv: this.data.rutDv,
        nombre: this.data.nombre,
        habilitado: this.data.habilitado,
        administrador: this.data.administrador,
        password: this.data.password,
        perfil: '',
        timezone: '',
        codLocal: this.codigoLocal,
        codCircunscripcion: this.codigoCircunscripcion
      }
    } else {
      usuarioNuevo = {
        rutUsuario: this.data.rut,
        rutDv: this.data.rutDv,
        nombre: this.data.nombre,
        habilitado: this.data.habilitado,
        administrador: this.data.administrador,
        password: this.data.password,
        perfil: '',
        timezone: '',
        codLocal: this.codigoLocal,
        codCircunscripcion: this.codigoCircunscripcion
      }
    }
    if (this.event == "new") {
      
      this.usuariosService.guardarUsuariosTerritorios(usuarioNuevo).subscribe(resp => {
        if (resp) {
          
          this.dialogEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
        }
      })
    } else {
     
      this.usuariosService.actualizarUsuariosTerritorios(usuarioNuevo).subscribe(resp => {
        if (resp) {
          
          this.dialogEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
        }
      })

    }
  }

  getTimeZones() {
    this.territorio.getTerritorioTimezones().subscribe(resp => {
      if (resp != null && resp.body['body'] != null && resp.body['body']['response']) {
        
        this.dtTimesZones = resp.body['body']['data'];

        this.getZone();

      }
    })
  }

  getZone() {
    if (this.data) {
      Object.keys(this.dtTimesZones).forEach(key => {
        if (this.dtTimesZones[key] == this.data.timezone) {
          this.itemZona = this.dtTimesZones[key];
        }
      });
    }
  }



}
