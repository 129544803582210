import { MessagesService } from 'src/app/services/messages.service';
import { RechazoModalComponent } from './component/modals/rechazo-modal/rechazo-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_ROUTING } from './app.routing';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from 'src/app/app.interceptor';
import { CustomMaterialModule } from 'src/app/app.material';
import { MessageComponent } from './component/modals/message/message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationComponent } from './component/navigation/navigation.component';
import { StoreModule } from '@ngrx/store';
import { sidenavReducer } from 'src/app/reducers/common.reducer';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { VistaConsulComponent } from './pages/vista-consul/vista-consul.component';
import { AdjuntarArchivosComponent } from './component/adjuntar-archivos/adjuntar-archivos.component';
import { CountDownComponent } from './component/count-down/count-down.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { InstalacionMesasComponent } from './pages/instalacion-mesas/instalacion-mesas.component';
import { ConstitucionMesasComponent } from './pages/constitucion-mesas/constitucion-mesas.component';
import { EscrutinioMesasComponent } from './pages/escrutinio-mesas/escrutinio-mesas.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VistaAdministracionComponent } from './component/vista-administracion/vista-administracion.component';
import { FiltrosTerritorialesComponent } from './component/vista-administracion/filtros-territoriales/filtros-territoriales.component';
import { FiltroEstadoActaComponent } from './component/vista-administracion/filtro-estado-acta/filtro-estado-acta.component';
import { FiltroAlertaActaComponent } from './component/vista-administracion/filtro-alerta-acta/filtro-alerta-acta.component';
import { WebsocketService } from 'src/app/services/websocket.service';
import { CreaUsuarioComponent } from './pages/usuarios/crea-usuario/crea-usuario.component';
import { ZonaHorariaComponent } from './pages/zona-horaria/zona-horaria.component';
import { CreaZonaComponent } from './pages/zona-horaria/crea-zona/crea-zona.component';
import { TerritorioUsuarioComponent } from './pages/usuarios/territorio-usuario/territorio-usuario.component';
import { CreaTerritorioUsuarioComponent } from './pages/usuarios/territorio-usuario/crea-territorio-usuario/crea-territorio-usuario.component';
import { DatePipe } from '@angular/common';
import { BreadcrumbsComponent } from './component/breadcrumbs/breadcrumbs.component';
import { ContinenteResumenComponent } from './component/vista-resumen/continente-resumen/continente-resumen.component';
import { VistaResumenComponent } from 'src/app/component/vista-resumen/vista-resumen.component';
import { PaisResumenComponent } from 'src/app/component/vista-resumen/pais-resumen/pais-resumen.component';
import { CircunscripcionResumenComponent } from 'src/app/component/vista-resumen/circunscripcion-resumen/circunscripcion-resumen.component';
import { LocalResumenComponent } from 'src/app/component/vista-resumen/local-resumen/local-resumen.component';
import { JuntaResumenComponent } from 'src/app/component/vista-resumen/junta-resumen/junta-resumen.component';

export function tokenGetter() {
  //console.log("devuelve token: " + localStorage.getItem('access_token'));
  //return localStorage.getItem('access_token');
  return localStorage.getItem('access_token');
  //return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiI5NjAiLCJleHAiOjE2MTc1NTk2MzMsImp0aSI6IjU0NzQxZGQ1LWI1MDctNDMzNC04YjFiLTVmOGQxYmEzMWJiYyJ9.AXU2m4Ig5RJX2pMBDAFo23PhZztGAjqR_0e9OkHjTC0q9MEVCxhvsl-rkb28vUxR92phhyQwicWMRVuWcaX1ZVwEWWhUkB2VpzsIKr3y3KmULs5FXe_7-eCqpqUDwmKn10eBwnPibDnYc5eI1yrFJPjRXqZwHUW9ODohCOvxP5KuSl2mfpRjyn6R_QyLRi1UU33LzldUA5LtdUpC38gTSGPAMR9YQAPhmJI9I4AZrgvUkYF-p6Q74kzzeiKYJ0gwBNkicO8CQgU0u75AegbQLzRBcLuyO2P0jvG1HpBf2UJ6Di_WvpUldyD-jPb0mgDAmEAZsBXw9TFSZr_RfGZfcg";
  //return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJydXQiOiIyLWsiLCJzdWIiOiJhZG1pbiIsImV2ZW50b0FjdGl2byI6IntcImlkXCI6NzksXCJub21icmVcIjpcIlByaW1hcmlhcyBHb2Jlcm5hZG9yIFJlZ2lvbmFsIHkgQWxjYWxkZVwiLFwiYW5uaW9cIjoyMDIwfSIsImlzcyI6ImFkbWluIiwicGVyZmlsZXMiOiJbIHtcbiAgXCJwZXJJZFwiIDogNTkzLFxuICBcInBlck5vbWJyZVBlcmZpbFwiIDogXCJBZG1pbmlzdHJhZG9yIFNpc3RlbWEgICAgICAgICAgICAgICAgICAgICAgICAgICAgIFwiLFxuICBcInBlckVsaW1pbmFkb1wiIDogZmFsc2UsXG4gIFwiY3JlYXRlZFwiIDogMTU5MjI2NTYwMDAwMCxcbiAgXCJtb2RpZmllYWRcIiA6IDE1OTIyNjU2MDAwMDAsXG4gIFwicGVyQ29kaWdvUGVyZmlsXCIgOiBcImFkbWluXCJcbn0gXSIsImV2ZW50b3MiOiJbIHtcbiAgXCJpZFwiIDogNzksXG4gIFwibm9tYnJlXCIgOiBcIlByaW1hcmlhcyBHb2Jlcm5hZG9yIFJlZ2lvbmFsIHkgQWxjYWxkZVwiLFxuICBcImFubmlvXCIgOiAyMDIwXG59LCB7XG4gIFwiaWRcIiA6IDU4LFxuICBcIm5vbWJyZVwiIDogXCJBbnRlcmlvciBHb3JlIHkgU2VuYWRvclwiLFxuICBcImFubmlvXCIgOiAyMDEzXG59LCB7XG4gIFwiaWRcIiA6IDU5LFxuICBcIm5vbWJyZVwiIDogXCJBbnRlcmlvciBEaXB1dGFkbywgU2VuYWRvciwgQ29yZVwiLFxuICBcImFubmlvXCIgOiAyMDE3XG59LCB7XG4gIFwiaWRcIiA6IDU3LFxuICBcIm5vbWJyZVwiIDogXCJBbGNhZGVzIHkgQ29uY2VqYWwgMjAxNlwiLFxuICBcImFubmlvXCIgOiAyMDE2XG59IF0iLCJpZCI6MjMzLCJleHAiOjE2MDIwODExNjcsIm5vbWJyZSI6ImFkbWluIiwianRpIjoiMGZkODYzNjItZTNmOS00MWJlLWE0YmItYmYzYTA3MGU3OWIyIiwidXNlcm5hbWUiOiJhZG1pbiJ9.UcfnNUdyWmtfD_ktmfP4LtD1Dv_q7FgMb85uzxsGwWk1JoT3XB3RjEkobV_0v8rSk9Ery3Dm_fqlTENIlO-uiW854x6_m1eSv1U__Vamh-mJavzicK1dZURaGLdJC7KsocHBJlrTBLNEtG-eW8HsO634Bkz9yTyIDUatCrN4srKbJ9V2WkXbMWieWeWAdezL3k06_0Cz1JmgPPDv1qVJgtkmKBfM1rLVzskhQ6KBji3mFlLxga7DjIqdOQQ_FNxs6c8YUIwlqbi9idgA-2vTjefvbQAzzh6oRaqFKbDgwsG62mCRXN8Nhy-nrJ5KJJlvmhOFYzh0TUsFQsLcc2PBiA";
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MessageComponent,
    NavigationComponent,
    HomeComponent,
    VistaConsulComponent,
    AdjuntarArchivosComponent,
    CountDownComponent,
    RechazoModalComponent,
    UsuariosComponent,
    InstalacionMesasComponent,
    ConstitucionMesasComponent,
    EscrutinioMesasComponent,
    VistaAdministracionComponent,
    FiltrosTerritorialesComponent,
    FiltroEstadoActaComponent,
    FiltroAlertaActaComponent,
    CreaUsuarioComponent,
    ZonaHorariaComponent,
    CreaZonaComponent,
    TerritorioUsuarioComponent,
    CreaTerritorioUsuarioComponent,
    VistaResumenComponent,
    BreadcrumbsComponent,
    PaisResumenComponent,
    JuntaResumenComponent,
    CircunscripcionResumenComponent,
    LocalResumenComponent,
    ContinenteResumenComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    APP_ROUTING,
    StoreModule.forRoot({ sidenav: sidenavReducer })
  ],
  providers: [    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }, MessagesService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
