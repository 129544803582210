<div *ngIf="data">
    <h2 mat-dialog-title>Datos del Parámetro</h2>
    
    <mat-dialog-content>
        <form [formGroup]="rechazogroup">
            <mat-form-field>
                <textarea matInput  formControlName="questionText" cols="35" rows="8" placeholder="Motivo Rechazo" [(ngModel)]="questionText" required></textarea>
                   </mat-form-field>
    </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-raised-button class="bg-blue" (click)="submit()" [disabled]="!rechazogroup.valid || !this.rechazo">Guardar</button>
      <button mat-button mat-raised-button class="bg-red" [mat-dialog-close]="true" id="cancelar">Cancelar</button>
    </mat-dialog-actions>
    
    </div>