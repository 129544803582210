<h2 mat-dialog-title>Datos del Usuario</h2>
<mat-dialog-content>
    <form [formGroup]="usuarios">
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
            <div fxFlex="60%">
                <mat-form-field class="full">
                    <input matInput type="text" maxlength="10" formControlName="rut" placeholder="rut" [required]="true" [disabled]="true">
                    <mat-error *ngIf="usuariosForm.rut.hasError('required')">
                        El campo es
                        <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="30%">

                <mat-form-field class="full">
                    <input matInput  type="text" maxlength="1" formControlName="rutDv" placeholder="Dv" [required]="true">
                    <mat-error *ngIf="usuariosForm.rutDv.hasError('required')">
                        El campo es
                        <strong>requerido</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <mat-form-field class="full">
            <input matInput type="text" maxlength="50" formControlName="nombre" placeholder="nombre" [required]="true">
            <mat-error *ngIf="usuariosForm.nombre.hasError('required')">
                El campo es
                <strong>requerido</strong>
            </mat-error>
        </mat-form-field>
        <p class="tradio-act">
            <span class="tt">Estado: </span>
            <mat-radio-group class="tradio-act" formControlName="estado">
                <mat-radio-button [value]=true color="warn">Habilitado</mat-radio-button>
                <mat-radio-button [value]=false color="warn">Deshabilitado</mat-radio-button>
            </mat-radio-group>
        </p>
        <!--         {{ this.valorPerfil | json}}  -->
        <p class="tradio-act">
            <span class="tt">Perfil: </span>
            <mat-radio-group class="tradio-act" [(value)]="valorPerfil"  formControlName="perfil" (change)="setAdministrador($event)">
                <mat-radio-button value="administrador" color="warn">Administrador</mat-radio-button>
                <mat-radio-button value="consul" color="warn">Cónsul</mat-radio-button>
                <mat-radio-button value="invitado" color="warn">Invitado</mat-radio-button>
            </mat-radio-group>
        </p>
        <p class="tradio-act">
                <span class="tt">Primer login: </span>
                <mat-radio-group class="tradio-act" formControlName="primerLogin">
                    <mat-radio-button [value]=true color="warn">Si</mat-radio-button>
                    <mat-radio-button [value]=false color="warn">No</mat-radio-button>
                </mat-radio-group>
            </p>
        <mat-form-field class="full" *ngIf="!passwordCargado">

            <input matInput placeholder="password" matInputAutofocus id="password" formControlName="password" minlength="5" [type]="hide ? 'password' : 'text'" >
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="usuariosForm.password.hasError('required') && !passwordCargado">
                El campo es
                <strong>requerido</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full" *ngIf="passwordCargado">

            <input matInput placeholder="password"  minlength="5" [type]="hide ? 'password' : 'text'" (input)="onChangePass($event.target.value)">
   
    
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button class="bg-blue" [mat-dialog-close]="true" id="cancelar">Cancelar</button>
    <button mat-button mat-raised-button class="bg-red" (click)="guardar()" [disabled]="!usuarios.valid">Guardar</button>
</mat-dialog-actions>