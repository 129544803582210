<div class="timer" [ngClass]="setColor()">
  <div  *ngIf="showAlert()">
  <span *ngIf="this.estadoAlerta=='ATRASADO'" style="margin-left: 1px">-</span><span id="hours" style="margin-left: 0px">{{hoursToDday}}</span>Hrs
  <span id="minutes" style="margin-left: 3px">{{minutesToDday}}</span>Min
</div>
</div>

 <!-- <span>HORAS: {{hoursToDday}} MINUTOS : {{minutesToDday}}  SEGUNDOS : {{secondsToDday}}</span> -->
<!--  <div class="timer" *ngIf="showFueraPlazo()" class="atrasado">
  <span>FUERA DE PLAZO</span>
</div>   -->