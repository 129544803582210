import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { CreaTerritorioUsuarioComponent } from 'src/app/pages/usuarios/territorio-usuario/crea-territorio-usuario/crea-territorio-usuario.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-territorio-usuario',
  templateUrl: './territorio-usuario.component.html',
  styleUrls: ['./territorio-usuario.component.css']
})
export class TerritorioUsuarioComponent implements OnInit {
  public nombre = new FormControl('', [Validators.required]);
  constructor(private usuariosService:UsuariosService,public utils: UtilsService,public dialog: MatDialog,public auth:AuthService) {

   }
  public displayedColumns: string[] = ['continente','pais','juntaElectoral','circunscripcionElectoral','localVotacion','timezone','acciones'];
  public dataSource:MatTableDataSource<any>;
  @Input() public data:any;
  @Output() public closeForm: EventEmitter<boolean> = new EventEmitter();
  public length:number = 0;
  eleccion:any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
    let authInfo = this.auth.getAuthInfo();
  this.getData();
  }

  
  public datosUsuario = new FormGroup({
    idUsuario: new FormControl({ value: '', disabled: true }, [Validators.required]),
    nombre: new FormControl({ value: '', disabled: true }, [Validators.required]),
    habilitado:new FormControl({ value: '', disabled: true }, [Validators.required]),
    administrador:new FormControl({ value: '', disabled: true }, [Validators.required]),
    zonaHoraria:new FormControl({ value: '', disabled: true }, [Validators.required])
    /* titulo: new FormControl('', [Validators.required]),
    descripcion: new FormControl('', [Validators.required]),
    fechaencuesta: new FormControl('', [Validators.required]),
    vigente: new FormControl('', [Validators.required]),
    territorio: new FormControl('') */
  });


  get datosUsuarioForm() {
    return this.datosUsuario.controls;
  }

  getData(){

    this.datosUsuarioForm.idUsuario.setValue(this.data.rut + "-" + this.data.rutDv);
    this.datosUsuarioForm.nombre.setValue(this.data.nombre);
    this.datosUsuarioForm.habilitado.setValue(this.muestraSiNo(this.data.habilitado));
    this.datosUsuarioForm.administrador.setValue(this.muestraSiNo(this.data.administrador));
    this.datosUsuarioForm.zonaHoraria.setValue(this.data.timezone);
    this.usuariosService.getUsuariosTerritorio(this.data.rut).subscribe(data =>{
      //this.paises= resp.data;
      
      let dt:any = (data.body['body']['data']) ? data.body['body']['data'] : [];
      //console.log("datos body: " + JSON.stringify(data['body']['data']));
      this.dataSource = new MatTableDataSource(dt);
      //console.log("datos body2: " + JSON.stringify(this.dataSource));
      this.length = dt.length;

      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };
       
        setTimeout( () =>{
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },500)
      });

  }



  creaTerritorio(data: any) {
    let dialogRef: any;
  
    if (data != undefined) {
      dialogRef = this.dialog.open(CreaTerritorioUsuarioComponent, {
        width: '600px',
        data: { data: data, nuevo: false }
      });
    } else {
      dialogRef = this.dialog.open(CreaTerritorioUsuarioComponent, {
        width: '600px',
        data: { data: this.data, nuevo:true }
      });
    }

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData();
      }
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  muestraSiNo(val:any){
    if (val){
      return 'Si';
    }else{
      return 'No';
    }
  }

  close(){
    this.closeForm.emit(true);
  }


}
