import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders,HttpResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VistaSupervisor } from '../models/VistaSupervisor';
import { VistaSupervisorExport } from 'src/app/models/VistaSupervisorExport';
import { VistaExport } from 'src/app/models/VistaExport';
@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(public http: HttpClient) { }


  public generatePdf(vistaExport: VistaExport) {
        return this.http.post<HttpResponse<Object>>(environment.baseApi+"/api/private/documentoRestService/exportPdf", vistaExport,{observe:'response'}); 
    
       
     }

  public generateCsv(vistaExport: VistaExport) {
 /*    return this.http.post(environment.baseApi+"/api/private/documentoRestService/exportPdf", vistaExport); */
 return this.http.post<HttpResponse<Object>>(environment.baseApi+"/api/private/reportecsv/exportCsv", vistaExport,{observe:'response'});
    
  }
}
