import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './services/utils.service';
import { AuthService } from './services/auth.service';
import { MatDialog } from '@angular/material/dialog';

import { tap } from "rxjs/internal/operators";
/* import { ErrorService } from './services/error.service'; */
import { LoginService } from 'src/app/services/login.service';
import { ErrorService } from 'src/app/services/error.service';
import jwt_decode from "jwt-decode";


@Injectable()
export class Interceptor implements HttpInterceptor {

  public reqClone:any;

  constructor(
     public errorService: ErrorService, 
    public utils: UtilsService,
    public auth: AuthService,
    private dialogRef: MatDialog,

    public loginService: LoginService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    setTimeout(() => {
      this.utils.showProgressBar();
    }, 200);
    let modifiedReq = request;
    if(!request.url.endsWith('getToken')){
        let token = this.auth.getToken();
        let currentUser:any = jwt_decode(token);
        let d = new Date();
        let startSession:any = this.auth.getStartSession();
        console.log(startSession);
        console.log("sessiontoken:"+(currentUser.exp-Math.floor(d.getTime()/1000))+"->"+request.url);
        console.log("sessionextendida[28800]:"+(Math.floor(d.getTime()/1000)-startSession))
        if((Math.floor(d.getTime()/1000)>currentUser.exp)){
            
                console.log("token expirado");
            
        }else if((currentUser.exp-Math.floor(d.getTime()/1000))<120 && Math.floor(d.getTime()/1000)-startSession<28800){
          if(!request.url.endsWith('getRefreshToken')){
          this.auth.getRefreshToken().subscribe(response=>{
            console.log("refreshed")
          })
        }   
      }
         modifiedReq = request.clone({ 
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
    } 
  

    return next.handle(modifiedReq).pipe(
      tap(event => {

        if (event instanceof HttpResponse) {
          setTimeout(() => {
            this.utils.hideProgressBar();

          }, 1000);
        }
      }, error => {
        this.errorService.errorNotificacion();

        if (error.status == 403 || error.status == 401) {
          this.auth.logout('/login')
        /*   this.utils.message("La sesión ha culminado. Debe autenticarse nuevamente.") */
        } else {
          this.dialogRef.closeAll();

        /*   if(request.url.indexOf('/partidoOrq/candidato/') < 0){
            let message: string = "Se ha detectado algunos inconvenientes. Contacte a soporte. ";
            if (error.status == 403 && error.error) { message = error.error }
             this.utils.message(message) 
           
          } */
        }

        setTimeout(() => {
          this.utils.hideProgressBar();
        }, 2500);

      })
    );

  }

}
