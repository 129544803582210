import { Acta } from 'src/app/models/Acta';

export class DocumentoExternoDTO {
    id: number;
    codigoActa: number;
    referenciaExt: string;
    nombre: string;
    descripcion: string;
    mimeType: string;
    linkExterno: string;
    base64: string;
  
}