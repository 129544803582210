import { TerritorioService } from 'src/app/services/territorio.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TipoNivel } from 'src/app/component/enums/tipo-nivel.enum';

@Component({
  selector: 'app-filtros-territoriales',
  templateUrl: './filtros-territoriales.component.html',
  styleUrls: ['./filtros-territoriales.component.css']
})
export class FiltrosTerritorialesComponent implements OnInit {
  dtContinentes: any;
  dtPais: any;
  dtJunta: any;
  dtCircunscripcion: any;
  dtLocal: any;

  itemContinente: any;
  itemPais: any;
  itemJunta: any;
  itemCircunscripcion: any;
  itemLocalVotacion: any;
  @Input() public limpiaFiltros:any;
  @Input() public territorioData: any;
  @Output() public localVotacionOutput: EventEmitter<any> = new EventEmitter(); 
  @Output() public circunscripcionOutput: EventEmitter<any> = new EventEmitter(); 
  @Output() public territorioOutput: EventEmitter<any> = new EventEmitter();
  @Output() public limpiaOutput: EventEmitter<any> = new EventEmitter();
  @Output() public validaFiltros: EventEmitter<any> = new EventEmitter();
  
  public territorios = new FormGroup({
    continente: new FormControl('',Validators.required),
    pais: new FormControl('',Validators.required),
    junta: new FormControl('',Validators.required),
    circunscripcion: new FormControl('',Validators.required),
    localVotacion: new FormControl('',Validators.required)
  });

  constructor(public territorioService: TerritorioService) { }

  ngOnInit(): void {
    this.getContinente(TipoNivel.CONTINENTE);
    if (this.limpiaFiltros) {
      this.filtroTerritoriosForm.continente.setValidators(null);
      this.filtroTerritoriosForm.pais.setValidators(null);
      this.filtroTerritoriosForm.junta.setValidators(null);
      this.filtroTerritoriosForm.circunscripcion.setValidators(null);
      this.filtroTerritoriosForm.localVotacion.setValidators(null);
    } 
  }


  get filtroTerritoriosForm() {
    return this.territorios.controls;
  }

  private getTerritorios() {
    this.territorioService.getTerritoriosTipo(TipoNivel.CONTINENTE).subscribe(resp => {
      
      resp.body['body']['response'] ? this.dtContinentes = resp.body['body']['data'] : [];
    })
  }

  getContinente(tipo: string) {
    this.territorioService.getTerritoriosTipo(tipo).subscribe(resp => {
      let respuesta = resp.body['body']['response'];
      
      if (respuesta) {
        let dt: any = (resp.body['body']['data']) ? resp.body['body']['data'] : [];
        this.dtContinentes = dt;
    /*     console.log("continentes: " + JSON.stringify(this.dtContinentes)); */
        

        if (!this.limpiaFiltros && this.territorioData!=null && this.territorioData.codContinente!=null) {
          this.cargaTerritorios();
    
        }
      }
    });
  }

  cargaTerritorios(){
    if ( this.territorioData){
    Object.keys(this.dtContinentes).forEach(key => {
      if (this.dtContinentes[key]['id'] == this.territorioData.codContinente) {
        this.itemContinente = this.dtContinentes[key];
      }
    });

    this.getTerritorioData(this.territorioData.codContinente,TipoNivel.CONTINENTE,TipoNivel.PAIS);
    this.getTerritorioData(this.territorioData.codPais,TipoNivel.PAIS,TipoNivel.JUNTA_ELECTORAL);
    this.getTerritorioData(this.territorioData.codJuntaElectoral,TipoNivel.JUNTA_ELECTORAL,TipoNivel.CIRCUNSCRIPCION_ELECTORAL);
    this.getTerritorioData(this.territorioData.codCircunscripcionElectoral,TipoNivel.CIRCUNSCRIPCION_ELECTORAL,TipoNivel.LOCAL_VOTACION);
    }
  }

  getTerritorioData(idTerritorio: number, tipoPadre: string, tipoHijo: string){
    
    this.territorioService.getTerritorioByIdTipo(idTerritorio, tipoPadre, tipoHijo).subscribe(resp => {
      
      let respuesta = resp.body['body']['response'];
      if (respuesta) {
        let dt: any = (resp.body['body']['data']) ? resp.body['body']['data'] : [];
        if (tipoPadre == TipoNivel.CONTINENTE) {
          this.dtPais = dt;
            /* console.log("itempais2: " + JSON.stringify(this.dtPais)); */
            Object.keys(this.dtPais).forEach(key => {
              if (this.dtPais[key]['idTerritorio'] == this.territorioData.codPais) {
                this.itemPais = this.dtPais[key];
              }
            });
        }else if (tipoPadre == TipoNivel.PAIS){
          this.dtJunta = dt;
         
            Object.keys(this.dtJunta).forEach(key => {
              if (this.dtJunta[key]['idTerritorio'] == this.territorioData.codJuntaElectoral) {
                this.itemJunta = this.dtJunta[key];
              }
            });
   

        }else if (tipoPadre == TipoNivel.JUNTA_ELECTORAL){
          this.dtCircunscripcion = dt;
          
  
            Object.keys(this.dtCircunscripcion).forEach(key => {
              if (this.dtCircunscripcion[key]['idTerritorio'] == this.territorioData.codCircunscripcionElectoral) {
                this.itemCircunscripcion = this.dtCircunscripcion[key];
              }
            });
          
        }else if (tipoPadre == TipoNivel.CIRCUNSCRIPCION_ELECTORAL){
          this.dtLocal = dt;
          if (!this.limpiaFiltros) {
        
            Object.keys(this.dtLocal).forEach(key => {
              if (this.dtLocal[key]['idTerritorio'] == this.territorioData.codLocal) {
                this.itemLocalVotacion = this.dtLocal[key];
              }
            });
          }
        }
        
        }
    });
  }

  getTerritorio(idTerritorio: number, tipoPadre: string, tipoHijo: string) {
    let territorio="";
     
      this.territorioService.getTerritorioByIdTipo(idTerritorio, tipoPadre, tipoHijo).subscribe(resp => {
        
        let respuesta = resp.body['body']['response'];
        if (respuesta) {
          let dt: any = (resp.body['body']['data']) ? resp.body['body']['data'] : [];
          if (tipoPadre == TipoNivel.CONTINENTE) {
            this.dtPais = dt;
            this.dtJunta=null;
            this.dtCircunscripcion=null;
            this.dtLocal=null
            territorio=TipoNivel.CONTINENTE;
            this.validaFiltros.emit(false);
          } else if (tipoPadre ==  TipoNivel.PAIS) {
            this.dtJunta=dt;
            this.dtCircunscripcion=null;
            this.dtLocal=null
            territorio=TipoNivel.PAIS;
            this.validaFiltros.emit(false);
          } else if (tipoPadre ==  TipoNivel.JUNTA_ELECTORAL) {
            this.dtCircunscripcion=dt;
            this.dtLocal=null
            territorio=TipoNivel.JUNTA_ELECTORAL;
            this.validaFiltros.emit(false);
          } else if (tipoPadre ==  TipoNivel.CIRCUNSCRIPCION_ELECTORAL) {
            this.dtLocal=dt;
            territorio=TipoNivel.CIRCUNSCRIPCION_ELECTORAL;
            this.circunscripcionOutput.emit(idTerritorio.toString());
            this.validaFiltros.emit(false);
          } else if (tipoPadre ==  TipoNivel.LOCAL_VOTACION) {
            /* console.log("emite local:" + idTerritorio); */
            this.localVotacionOutput.emit(idTerritorio.toString());
            this.validaFiltros.emit(true);
         //   this.loadData(resp);
          }
          if (territorio!=""){
          this.territorioOutput.emit("{\""+ territorio + "\": \"" + idTerritorio.toString() + "\"}");
          }
        }
      })
  } 

  limpiar(){
    this.dtContinentes=null
    this.dtPais = null;
    this.dtJunta=null;
    this.dtCircunscripcion=null;
    this.dtLocal=null
    this.getContinente(TipoNivel.CONTINENTE);
    this.limpiaOutput.emit(true);
  }

}
