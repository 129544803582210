<h1 class="display-5 title-app">
  Territorios Usuario</h1>
<div *ngIf="dataSource">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
    <div fxFlex="85%">
      <mat-form-field class="full">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Búsqueda Avanzada">
      </mat-form-field>
    </div>
    <div fxFlex="10%" *ngIf="this.auth.getAuthInfo()['tokenInfo']['perfil']=='administrador'">
      <button mat-button mat-raised-button class="bg-blue phase noborder full" id="nuevo" (click)="creaTerritorio()">Asociar territorio</button>
    </div>
    <div class="btn-section">
      <button mat-button mat-raised-button color="warn" class="btn-section" (click)="close()" style="margin-right:15px;margin-bottom: 10px">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Volver</button>
      <h3 class="title-app mat-h1 mat-headline">Datos Usuario</h3>

    </div>
  </div>
  <!--     <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="center">
    <h3 class="title-app mat-h1 mat-headline">
      Paises</h3>
    </div> -->
  <form [formGroup]="datosUsuario">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
      <div fxFlex="10%">
        <mat-form-field class="full">
          <input matInput type="text" maxlength="50" formControlName="idUsuario" placeholder="Rut Usuario" [required]="true">
          <mat-error *ngIf="datosUsuarioForm.idUsuario.hasError('required')">
            El campo es
            <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <mat-form-field class="full">
          <input matInput type="text" maxlength="50" formControlName="nombre" placeholder="Nombre Usuario" [required]="true">
          <mat-error *ngIf="datosUsuarioForm.nombre.hasError('required')">
            El campo es
            <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
          <mat-form-field class="full">
            <input matInput type="text" maxlength="50" formControlName="habilitado" placeholder="Habilitado" [required]="true">
            <mat-error *ngIf="datosUsuarioForm.habilitado.hasError('required')">
              El campo es
              <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="20%">
            <mat-form-field class="full">
              <input matInput type="text" maxlength="50" formControlName="administrador" placeholder="Administrador" [required]="true">
              <mat-error *ngIf="datosUsuarioForm.administrador.hasError('required')">
                El campo es
                <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="20%">
              <mat-form-field class="full">
                <input matInput type="text" maxlength="50" formControlName="zonaHoraria" placeholder="Zona Horaria" [required]="true">
                <mat-error *ngIf="datosUsuarioForm.zonaHoraria.hasError('required')">
                  El campo es
                  <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
            </div>
    </div>
  </form>
  <div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="continente">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Continente</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.continente}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pais">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pais</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.pais}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="juntaElectoral">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Junta Electoral</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.juntaElectoral}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="circunscripcionElectoral">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Circunscripción Electoral</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.circunscripcionElectoral}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="localVotacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Local Votación</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.localVotacion}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timezone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Zona Horaria</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.timezone}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let val" data-label="Acciones">
          <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones" matTooltipPosition="right" *ngIf="this.auth.getAuthInfo()['tokenInfo']['perfil']=='administrador'">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="creaTerritorio(val, 'edit')">
              <mat-icon>create</mat-icon>
              <span>Editar</span>
            </button>

          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="length == 0" class="noresults">No se encontraron resultados</div>
    <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>