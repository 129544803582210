<h2 mat-dialog-title>Datos Zona Horaria</h2>
<mat-dialog-content>
<form [formGroup]="zonas">
    <div *ngIf="this.data">
<app-filtros-territoriales [limpiaFiltros]="false" [territorioData]="this.data"  #filtroTerritorial></app-filtros-territoriales>
</div>
<mat-form-field class="full">
    <mat-label>Zona Horaria</mat-label>
<mat-select matTooltip="Seleccione una zona horaria" [(ngModel)]="itemZona" formControlName="zona" >
    <mat-option *ngFor="let item of dtTimesZones" [value]="item">{{item}}</mat-option>
</mat-select>
</mat-form-field>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button class="bg-blue" [mat-dialog-close]="true" id="cancelar">Cancelar</button>
    <button mat-button mat-raised-button class="bg-red" (click)="guardar()" [disabled]="!zonas.valid">Guardar</button>
</mat-dialog-actions>