import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Store } from '@ngrx/store';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private router: Router, private auth: AuthService, public utils: UtilsService, private store: Store<{ sidenav: boolean }>) { }
 

  evaluaAccesos(token: any,clave: string,isRedirect: boolean) {
    if (!token) {
      this.utils.mDialog("Error", "Usuario y/o Contraseña son incorrectos.", "message");
    } else {
      
        let tokenInfo = jwt_decode(token);

        let nowObj = new Date(tokenInfo['exp'] * 1000);
        let nowDate = nowObj.getFullYear() + '-' + (nowObj.getMonth() + 1) + '-' + nowObj.getDate();
        let nowTime = nowObj.getHours() + ':' + nowObj.getMinutes() + ':' + nowObj.getSeconds();

        let userFound = {
          sessiontime: nowDate + ' ' + nowTime,
          access_token: token,
          tokenInfo: tokenInfo,
          sessionTimeExp: tokenInfo['exp'] * 1000,
          startSession: Math.round((new Date()).getTime()/1000)           
        }

        this.auth.setAuth(userFound);
        this.utils.updateSessionInfo();
        if (isRedirect)
        this.redirectUsuario();
    
    }
  }

  redirectUsuario() {
    
    try {
      if (this.auth.isAuth()) {

        let authInfo = this.auth.getAuthInfo();


        this.router.navigate(['home']);

        if (window.innerWidth >= 1400) {
          setTimeout(() => {
            this.store.dispatch({ type: 'ACTIVO' });
          }, 500)
        }

      }
    } catch (error) {
      localStorage.clear();
    }
  }

  autenticate(rut:number,dv:string, pass: string, passNew: string) {
    
    var promise = new Promise((resolve, reject) => {
      this.auth.login(rut,dv, pass, passNew).subscribe((resp:any) => {
        if (resp != null && resp['body']['data'] && !resp['body']['data']['primerLogin']) {
         
          resolve(resp['body']['data']['token']);
 
        } else {
          reject(resp['body']);
        }
      });
    });

    return promise;
  }

  redirectUser(){
    if (this.auth.isAuth()) {
    this.router.navigate(['home']);
    }
  }
}
