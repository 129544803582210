import { estadoActa } from 'src/app/component/enums/estado-acta.enum';
import { Mensaje } from 'src/app/models/message';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';
import { ActaService } from '../../../services/acta.service';
import { Acta } from '../../../models/Acta';

@Component({
  selector: 'app-rechazo-modal',
  templateUrl: './rechazo-modal.component.html',
  styleUrls: ['./rechazo-modal.component.css']
})
export class RechazoModalComponent implements OnInit {


  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  public selectTerritorio: string;
  public nuevo: boolean;
  public data: any;
  public tipo: string;
  public acta: Acta;
  
  public itemTerritorioPadre: any;
  

  public dtTerritorios: any;
  public questionText: any;
  msg: Mensaje;
  public rechazo: boolean;
  
  public rechazogroup = new FormGroup({
    questionText : new FormControl('', [Validators.required])
   
  });
  get rechazoForm() {
    return this.rechazogroup.controls;
  }
  constructor(
    public dialogRef: MatDialogRef<RechazoModalComponent>,
    public utils: UtilsService,
    public actaService: ActaService,
    @Inject(MAT_DIALOG_DATA) public mData: { data: any, rechazo: boolean, nuevo:boolean},
    public auth:AuthService) {
    this.data = mData.data;
    this.rechazo = mData.rechazo;
    this.nuevo = mData.nuevo;
  
 
    this.rechazoForm.questionText.setValue(this.data.motivoRechazo);
    if (!this.nuevo) {
      this.questionText = this.data.motivoRechazo;
    }

    if (this.auth.getAuthInfo()['tokenInfo']['perfil']!='administrador' || !this.rechazo)
      this.rechazoForm.questionText.disable();

  }


  getTerritorio() {
    Object.keys(this.dtTerritorios).forEach(key => {
      if (this.dtTerritorios[key]['idTerritorio'] == this.data.idTerritorioPadre) {
        this.itemTerritorioPadre = this.dtTerritorios[key];
      }
    });
  }

  ngOnInit() {

  }



  submit() {

    this.acta= new Acta();
 this.acta.idActa=this.data['idActa'];
 this.acta.motivoRechazo= this.rechazoForm.questionText.value;
  //this.acta.estado=estadoActa.RECHAZADA;
  let key=Object.keys(estadoActa).find( x=> estadoActa[x] == estadoActa.RECHAZADA);
  this.acta.estado= key;
    this.actaService.actualizaActa(this.acta).subscribe(resp =>{
      if (resp) {
        
       // this.sendMsg();
        this.dialogEvent.emit(resp);
        this.dialogRef.close();
        
      } else {
        this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
      }

    });
  
  }


}
