export class Acta {
    idActa: number;
    tipoCodigo: number;
    idUsuario: number;
    codigoLocalVotacion: string;
    descripcionLocalVotacion: string;
    codigoMesa: string;
    descripcionMesa: string;
    estado: string;
    fechaCumplimiento: Date;
    motivoRechazo: string;
}   