import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-continente-resumen',
  templateUrl: './continente-resumen.component.html',
  styleUrls: ['./continente-resumen.component.css']
})
export class ContinenteResumenComponent implements OnInit {
public breadCrumbs:any;
  constructor() { }

  ngOnInit(): void {
   this.breadCrumbs= {
      t: "",
      b: [
        { n: 'Continentes', r: '/vista-resumen', q:{idProceso: 1}  },
/*           { n: 'Menú', r: '/menu' } */
      ]
    }
  }

}
