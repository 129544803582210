import { Component, HostListener, ViewChild, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Funcionalidades } from 'src/app/component/enums/funcionalidades.enum';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  @ViewChild("sidenav") sideElement: any;
  data: any;
 /*  menuItems:any;
  mapMenu:any; */
  ngOnInit(): void {

    let token = this.auth.getToken();
    if(token != undefined && token!=""){
        let currentUser:any = jwt_decode(token);
        let d = new Date();
        console.log("sessiontoken:"+(currentUser.exp-Math.floor(d.getTime()/1000)));
        if((Math.floor(d.getTime()/1000)>currentUser.exp)){
                console.log("token expirado");
                this.cerrarSesion()
            
        }
      }
    this.onResize();

  }


  ngAfterViewInit(){

  }

  title = 'repepcion-datos-extranjero';
  public modeSideNav:string="";
  constructor(public auth:AuthService, public utils: UtilsService,    private store:Store<{sidenav: boolean}>,  public router:Router) { }

  cerrarSesion() {
    //this.sideElement.close();
    console.log("cierra sesion");
    this.removeAuthInfo();
    this.auth.logout('/login');
  }

  removeAuthInfo() {
    localStorage.clear();
  }
  
  getData() {

  }

  config = {
    placeholder: '',
    tabsize: 2,
    height: '800px',
    uploadImagePath: '/api/upload',
    toolbar: [
        ['misc', ['codeview', 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
        ['insert', ['table', 'picture', 'link', 'video', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }
  @HostListener('window:resize', ['$event'])
  onResize() {

      if(window.innerWidth >= 600){
        this.modeSideNav = 'side';
    //    this.store.dispatch({type:'ACTIVO'});
      }else{
        this.modeSideNav = 'over';
        this.store.dispatch({type:'INACTIVO'});
      }
  }
}
