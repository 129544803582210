import { VistaSupervisorExport } from 'src/app/models/VistaSupervisorExport';
import { VistaResumenExport } from 'src/app/models/VistaResumenExport';
import { InformeEnum } from 'src/app/component/enums/informe-enum';
import { VistaZona } from 'src/app/models/VistaZona';

export class VistaExport{
     vistaSupervisorExport:VistaSupervisorExport;
	 vistaResumenExport: VistaResumenExport;
	 vistaZona: VistaZona[];
	 informeEnum:InformeEnum; 
}