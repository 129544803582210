import { TerritorioService } from 'src/app/services/territorio.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UsuariosService } from './../../../services/usuarios.service';
import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FiltrosTerritorialesComponent } from 'src/app/component/vista-administracion/filtros-territoriales/filtros-territoriales.component';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-crea-usuario',
  templateUrl: './crea-usuario.component.html',
  styleUrls: ['./crea-usuario.component.css']
})
export class CreaUsuarioComponent implements OnInit {
  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  public data: any;
  public estado: boolean;
/*   public administrador: boolean; */
  hide = true;
  public event: string;
  public dtTimesZones: any;
  public itemZona:any;
  public validaFiltro: boolean=true;
  public codigoLocal:number;
  public isAdministrador:boolean;
  valorPerfil: any;
  primerLogin:boolean;
  passwordCargado:boolean=false;
  @ViewChild('passInput') passInput: ElementRef;

  public usuarios = new FormGroup({
    rut: new FormControl('', [Validators.pattern("^[0-9]*$")]),
    rutDv: new FormControl(''),
    nombre: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    perfil: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    primerLogin:new FormControl('', [Validators.required])
  });

  get usuariosForm() {
    return this.usuarios.controls;
  }
  constructor(private usuariosService: UsuariosService,
    public dialogRef: MatDialogRef<CreaUsuarioComponent>, private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public mData: { data: any }, private territorio:TerritorioService,private cdRef:ChangeDetectorRef,private renderer: Renderer2) {
    this.data = mData.data;
  /*   console.log("data usuario:" + JSON.stringify(this.data)); */
    if (this.data) {
      this.usuariosForm.rut.setValue(this.data.rut);
      this.usuariosForm.rutDv.setValue(this.data.rutDv);
      this.usuariosForm.nombre.setValue(this.data.nombre);
      this.usuariosForm.estado.setValue(this.data.habilitado);
      this.isAdministrador=this.data.administrador;
/*       this.usuariosForm.administrador.setValue(this.data.administrador); */
       this.usuariosForm.password.setValue(this.data.password); 
      if (this.data.password!=null && this.data.password!=undefined){
        this.passwordCargado=true;
      }
      this.usuariosForm.rut.disable();
      this.usuariosForm.rutDv.disable();
      this.codigoLocal= this.data.codLocal;
      this.usuariosForm.primerLogin.setValue(this.data.primerLogin);
      this.usuariosForm.perfil.setValue(this.data.perfil);
      setTimeout(() => {
        this.valorPerfil=this.data.perfil;
   /*    this.primerLogin=this.data.primerLogin; */
    }, 500);
      this.event = "edit";
      this.usuariosForm.password= new FormControl('');
    } else {
      this.event = "new";
      this.usuariosForm.rut.enable();
      this.usuariosForm.rutDv.enable();
      this.usuariosForm.password= new FormControl('', [Validators.required]),
      this.isAdministrador=false;
      this.primerLogin=true;
    }

  }

  ngAfterViewChecked()
{
  this.cdRef.detectChanges();
}

  ngOnInit(): void {
    setTimeout( () =>{
    if (this.isAdministrador){
     
      this.validaFiltro=true;
    }
  },500);
  }
  guardar() {
   /*  console.log("data22: " + JSON.stringify(this.usuariosForm.rut.value)); */
    let usuarioNuevo = {
      rutUsuario: this.usuariosForm.rut.value,
      rutDv: this.usuariosForm.rutDv.value,
      nombre: this.usuariosForm.nombre.value,
      habilitado: this.usuariosForm.estado.value,
      password: this.usuariosForm.password.value,
      perfil:this.usuariosForm.perfil.value,
      primerLogin:this.usuariosForm.primerLogin.value,
      administrador:this.isAdministrador
    }
    if (this.event == "new") {
   /*    console.log("guarda " + JSON.stringify(usuarioNuevo) + "cod local: " + this.codigoLocal); */
      this.usuariosService.guardarUsuarios(usuarioNuevo).subscribe(resp => {
        if (resp) {
          
          this.dialogEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
        }
      })
    }else{
    /*   console.log("actualiza " + JSON.stringify(usuarioNuevo) + "cod local: " + this.codigoLocal); */
      this.usuariosService.actualizaUsuarios(usuarioNuevo).subscribe(resp => {
        if (resp) {
          
          this.dialogEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
        }
      })

    }
  }


  setAdministrador(event: any){
  
    if (event.value=="administrador"){
      this.isAdministrador=true;
    }else{
      this.isAdministrador=false;
    }
 /*    console.log("setea administrador: " + this.isAdministrador); */
      this.validaFiltro=true;

    
  }

  onChangePass(val){
/*     console.log("val password: " + val); */
    this.usuariosForm.password.setValue(val); 
    this.passwordCargado=false;
/*     this.passInput.focus(); */
setTimeout(() => {
this.renderer.selectRootElement('#password').focus();
}, 100);
  }

}
