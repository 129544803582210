import {  Mensaje } from './../../models/message';
import { MessagesService } from 'src/app/services/messages.service';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Inject } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CONF } from 'src/environments/config';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentoExternoDTO } from 'src/app/models/DocumentoExternoDto';
import { DocumentoExternoService } from 'src/app/services/documento-externo.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Acta } from 'src/app/models/Acta';

@Component({
  selector: 'app-adjuntar-archivos',
  templateUrl: './adjuntar-archivos.component.html',
  styleUrls: ['./adjuntar-archivos.component.css']
})
export class AdjuntarArchivosComponent implements OnInit {
  selectedFiles: FileList;
  file: File;
  currentFile: File;
  progress = 0;
  mensaje = '';
  itemEstado: any;

  fileInfos: Observable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public data: any
  public displayedColumns: string[] = ['codigo','nombre','descripcion','type','size','acciones'];
  public dataSource:MatTableDataSource<any>;
  public length:number = 0;
  public tipoVisualizacion: boolean = false;
  public documentoExternoDTO: DocumentoExternoDTO;
 // public dtVistas: any;
 // public itemVista: any;
 // public itemVistaEstado: any;
 // public dtVistasEstado: any;
  public editor: boolean;
  public size: number;
  websocket: any;
  wsUri: string;
  msg: Mensaje;
  isExtension:boolean=false;
  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();

/*   public vistas = new FormGroup({
    vista : new FormControl('', [Validators.required]),
    vistaEstado: new FormControl('', [Validators.required])
  });
  get vistasForm() {
    return this.vistas.controls;
  } */

  constructor(private documentoExternoService: DocumentoExternoService,public utils:UtilsService,
    public dialogRef: MatDialogRef<AdjuntarArchivosComponent>,
    @Inject(MAT_DIALOG_DATA) public mData: { data: any}) {
 
      
      
     this.data = mData.data;
     
   }

   

  ngOnInit() {
    //this.fileInfos = this.uploadService.getFiles();
   // this.getData();
    //this.getVistas();
  }
  examinar(){
    document.getElementById("inputFile").click();
  }






  fillData(dt: any) {
    this.dataSource = new MatTableDataSource(dt);
    //console.log("datos body2: " + JSON.stringify(this.dataSource));
    this.length = dt.length;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
      return data[sortHeaderId];
    };

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 500)
  }

  selectFile(event) {
 
     this.documentoExternoDTO= new DocumentoExternoDTO();
    this.selectedFiles = event.target.files;
    this.file = event.target.files[0];
    
    this.size= this.file.size/1024;
    

   
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      let strArchivo= reader.result as string;
        if (strArchivo.indexOf("application/pdf")>-1){
          this.isExtension=true;
        }else{
          this.isExtension=false;
        }
        this.documentoExternoDTO.base64=reader.result as string;
    }; 
  /* }else{
    alert("Extensión no permitida: " + ext);
  } */
    
  }


  upload() {
    let size = Math.round((this.selectedFiles.item(0)['size'] / 1024) * 100) / 100;
    if (this.isExtension){
    if (size <= CONF.maxFileUploadInBytes) {
    this.currentFile = this.selectedFiles.item(0);
   


    this.documentoExternoDTO.nombre=this.currentFile.name;
    this.documentoExternoDTO.mimeType=this.currentFile.type;
   
    this.documentoExternoDTO.codigoActa=this.data['idActa'];

    let acta: Acta;
    acta= new Acta();



    
    this.documentoExternoService.saveFile(this.documentoExternoDTO).subscribe(res=>{
      
      this.dialogEvent.emit(true);
      //this.utils.uploadActaEvent(1);
       //this.send_message(this.documentoExternoDTO);
     //  this.sendMsg();
       this.dialogRef.close(); 

    })
  }else{
    let maxyte = CONF.maxFileUploadInBytes / 1024;
    this.utils.message("El archivo no puede ser mayor ser mayor a "+maxyte+"MB");
  }
}else{
  this.utils.message("El acta debe ser en formato pdf");
}

}


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  activaGuardar() {
    let validaCampos: boolean;
  //  console.log("vistasForm.valid: " + this.vistas.valid + "this.selectedFiles: " + this.selectedFiles);
   /*  if (!this.data.isConsulado){ */
      if (this.selectedFiles!=undefined) {
       /*  console.log("entra a false"); */
        validaCampos = false;
      } else {
        /* console.log("entra a true"); */
        validaCampos = true;
      }

    
    return validaCampos;
  }
}
