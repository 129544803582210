<h2 mat-dialog-title>Archivos a subir</h2>
<mat-dialog-content>
<!--     <form [formGroup]="vistas"> -->
  <div class="alert alert-light" role="alert">{{ mensaje }}</div>
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
      <div fxFlex="30%">
      <button mat-button mat-raised-button class="bg-blue phase noborder" id="nuevo" (click)="examinar()">Examinar</button>
   </div>
   <div fxFlex="30%">
      
      <div *ngIf="selectedFiles">
        <label>{{selectedFiles[0].name }} {{this.size}} kb</label>
      </div>
    </div>
  </div>
<!--   <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
    <div fxFlex="10%">
      <button mat-button mat-raised-button class="bg-blue phase noborder full" id="nuevo" (click)="examinar()">Examinar</button>
    </div>
    <div fxFlex="10%">
      <button mat-button mat-raised-button class="bg-blue phase noborder full" [disabled]="!selectedFiles" id="subir" (click)="upload()">Subir</button>
      <div *ngIf="selectedFiles">
        <label>{{selectedFiles[0].name }} {{selectedFiles[0].size}} kb</label>
      </div>
    </div>
 -->
      
  <label class="btn btn-default">
      <input type="file" id="inputFile" (change)="selectFile($event)" accept="application/pdf" hidden multiple />
    </label>
<!--     <div *ngIf="dtVistas">
      <mat-form-field class="full">
        <mat-label>Tipo de Vista</mat-label>
        <mat-select matTooltip="Seleccione tipo de vista" (selectionChange)="getVistaEstado(this.itemVista.codigomenu)" [disabled]="this.editor" [(ngModel)]="itemVista"
          formControlName="vista">
          <mat-option *ngFor="let item of dtVistas" [value]="item">{{item.descripcion}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="!this.data.isConsulado">
      <mat-form-field class="full">
        <mat-label>Vistas publicadas</mat-label>
        <mat-select matTooltip="Seleccione una vista" [(ngModel)]="itemVistaEstado" [disabled]="this.editor" formControlName="vistaEstado">
          <mat-option *ngFor="let item of dtVistasEstado" [value]="item">{{item.descripcion}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </div> -->
  
<!--   </div> -->

  <div *ngIf="currentFile" class="progress">
    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar" attr.aria-valuenow="{{ progress }}" aria-valuemin="0"
      aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
      {{ progress }}%
    </div>
  </div>

<!-- </form> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button class="bg-blue" (click)="upload()" [disabled]="activaGuardar()!">Guardar</button>
  <button mat-button mat-raised-button class="bg-red" [mat-dialog-close]="true" id="cancelar">Cancelar</button>
</mat-dialog-actions>
