import { VistaAdministracionComponent } from './../../component/vista-administracion/vista-administracion.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-constitucion-mesas',
  templateUrl: './constitucion-mesas.component.html',
  styleUrls: ['./constitucion-mesas.component.css']
})
export class ConstitucionMesasComponent implements OnInit {
idProceso: number;
titulo: string;


  constructor() { }

  ngOnInit(): void {
    this.idProceso=1;
    this.titulo="Constitución de Mesas";
  }

}
