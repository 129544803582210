import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(public http: HttpClient) { }
getUsuarios(){
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios",{observe:'response'});
}

getUsuariosTerritorio(idUsuario:number){
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios/territorios/" + idUsuario,{observe:'response'})
}

guardarUsuarios(usuario: any){
  return this.http.post<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios",usuario,{observe:'response'});
}


actualizaUsuarios(usuario: any){
  return this.http.put<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios",usuario,{observe:'response'});
}



guardarUsuariosTerritorios(usuario: any){
  return this.http.post<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios/territorios",usuario,{observe:'response'});
}
actualizarUsuariosTerritorios(usuario: any){
  return this.http.put<HttpResponse<Object>>(environment.baseApi+"/api/private/usuariosRestService/usuarios/territorios",usuario,{observe:'response'});
}

}
