
import { ConstitucionMesasComponent } from './pages/constitucion-mesas/constitucion-mesas.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { VistaConsulComponent } from './pages/vista-consul/vista-consul.component';
import { UsuariosComponent } from 'src/app/pages/usuarios/usuarios.component';
import { InstalacionMesasComponent } from './pages/instalacion-mesas/instalacion-mesas.component';
import { EscrutinioMesasComponent } from './pages/escrutinio-mesas/escrutinio-mesas.component';
import { ZonaHorariaComponent } from './pages/zona-horaria/zona-horaria.component';
import { VistaResumenComponent } from './component/vista-resumen/vista-resumen.component';
import { PaisResumenComponent } from 'src/app/component/vista-resumen/pais-resumen/pais-resumen.component';
import { ContinenteResumenComponent } from './component/vista-resumen/continente-resumen/continente-resumen.component';
import { JuntaResumenComponent } from 'src/app/component/vista-resumen/junta-resumen/junta-resumen.component';
import { CircunscripcionResumenComponent } from 'src/app/component/vista-resumen/circunscripcion-resumen/circunscripcion-resumen.component';
import { LocalResumenComponent } from 'src/app/component/vista-resumen/local-resumen/local-resumen.component';



/* @NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) */

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, data: {title: ''}},
  {path: 'home', component:HomeComponent},
  {path: 'usuarios', component:UsuariosComponent},
  {path: 'constitucion-mesas', component:ConstitucionMesasComponent},
  {path: 'instalacion-mesas', component:InstalacionMesasComponent},
  {path: 'escrutinio-mesas', component:EscrutinioMesasComponent},
  {path: 'consul', component:VistaConsulComponent},
  {path: 'zona-horaria', component:ZonaHorariaComponent},
  {path: 'vista-resumen', component:VistaResumenComponent},
  {path: 'continente-resumen', component:ContinenteResumenComponent},
  {path: 'pais-resumen', component:PaisResumenComponent},
  {path: 'junta-resumen', component:JuntaResumenComponent},
  {path: 'circunscripcion-resumen', component:CircunscripcionResumenComponent},
  {path: 'local-resumen', component:LocalResumenComponent},
  { path: '**', pathMatch: 'full', redirectTo: '/login' }
];
export const APP_ROUTING = RouterModule.forRoot(routes, { useHash: true });
/* export class AppRoutingModule { } */
