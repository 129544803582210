import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthService {

  constructor(private router: Router, public http: HttpClient,public utils:UtilsService,private store: Store<{ sidenav: boolean }>) { }

  login(rut:number,dv:string, pass: string, passNew: string) {
    return this.http.post(environment.baseApi+"/api/auth/getToken", { rut: rut, dv: dv, password: pass, passwordNew: passNew });
    }
  getRefreshToken() {
      return this.http.post(environment.baseApi+"/api/auth/getRefreshToken",{}).pipe(
        map((response: any) => {
            // login successful if there's a jwt token in the response
            let token = response.token
            let tokenInfo = jwt_decode(token);

            let nowObj = new Date(tokenInfo['exp'] * 1000);
            let nowDate = nowObj.getFullYear() + '-' + (nowObj.getMonth() + 1) + '-' + nowObj.getDate();
            let nowTime = nowObj.getHours() + ':' + nowObj.getMinutes() + ':' + nowObj.getSeconds();
    
            let userFound = {
              sessiontime: nowDate + ' ' + nowTime,
              access_token: token,
              tokenInfo: tokenInfo,
              sessionTimeExp: tokenInfo['exp'] * 1000,
              startSession:this.getStartSession()       
            }
            this.setAuth(userFound);
                // set token propert
                return true;
          
        })
    );
  }



  isAuth() {
     
      let crd: any = this.getAuthInfo();
      if (crd['tokenInfo'] && crd['tokenInfo'] != "" ) {
        return true;
      }
  
      if (this.router.url != "/" && this.router.url != "/login") {
        this.utils.message("La sesión ha culminado. Debe autenticarse nuevamente")
        this.removeAuthInfo();
        this.router.navigate(['/login']);
      }
  
      return false;
    }
    removeAuthInfo() {
      localStorage.clear();
    }
  
    setAuth(jsonCookie: any) {
      localStorage.setItem('authInfo', JSON.stringify(jsonCookie));
  
      Object.keys(jsonCookie).forEach(key => {
        if (key == 'access_token')
          localStorage.setItem('access_token', jsonCookie[key]);
        if (key == 'startSession')
          localStorage.setItem('startSession', jsonCookie[key]);
      })
    }

    getToken() {
      return localStorage.getItem('access_token');
    }

    getStartSession(){
      return localStorage.getItem('startSession');
    }
  
    getAuthInfo() {
      let authInfo: any = {};
      if (localStorage['authInfo']) {
        let jsonData = JSON.parse(localStorage['authInfo']);
        Object.keys(jsonData).forEach(key => {
          if (key == 'tokenInfo')
        /*     Object.keys(jsonData[key]).forEach(keyToken => {
              if (keyToken == 'perfiles') {
                authInfo['rol'] = JSON.parse(jsonData[key][keyToken]);
              } else
                authInfo[keyToken] = jsonData[key][keyToken];
            }); */
  
          authInfo[key] = jsonData[key];
        })
      }
      return authInfo;
    }
  
    logout(route: string = "/") {
      this.router.navigate([route]);
      this.removeAuthInfo();
      this.store.dispatch({ type: 'INACTIVO' })
    }
}
