import { UtilsService } from './../../services/utils.service';
import { DocumentoExternoService } from './../../services/documento-externo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActaService } from './../../services/acta.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DocumentoExternoDTO } from 'src/app/models/DocumentoExternoDto';
import { CONF } from 'src/environments/config';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdjuntarArchivosComponent } from 'src/app/component/adjuntar-archivos/adjuntar-archivos.component';
import { RechazoModalComponent } from 'src/app/component/modals/rechazo-modal/rechazo-modal.component';
import { TipoNivel } from '../../component/enums/tipo-nivel.enum';
import { estadoActa } from 'src/app/component/enums/estado-acta.enum';
import { TerritorioService } from 'src/app/services/territorio.service';
import { MessagesService } from 'src/app/services/messages.service';
import { tokenGetter } from '../../app.module';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-vista-consul',
  templateUrl: './vista-consul.component.html',
  styleUrls: ['./vista-consul.component.css']
})
export class VistaConsulComponent implements OnInit,OnDestroy {
  data: any;
  constructor(public actaService: ActaService, public auth: AuthService, public documentoExternoService: DocumentoExternoService,
    public utils: UtilsService, public dialog: MatDialog, public territorioService: TerritorioService

  ) { }
  public displayedColumnsIns: string[] = ['proceso', 'estadoacta', 'cumplimientohito', 'alertaenvioacta', 'adjuntaracta', 'verrechazo'];
  public displayedColumnsEsc: string[] = ['mesa', 'estadoacta', 'cierremesa', 'alertaenvioacta', 'adjuntaracta', 'verrechazo'];
  public dataSourceIns: MatTableDataSource<any>;
  public dataSourceEsc: MatTableDataSource<any>;
  public lengthIns: number = 0;
  public lengthEsc: number = 0;
  dtLocalVotacion: any;
  dtCircunscripcion: any;
  itemLocalVotacion: any;
  itemCircunscripcion: any;
  nombrePais: any;
  titulosNombres: string = "";
  currentFile: File;
  rutStr: string;
  rut: string;
  arrStr: string[] = [];
  subscriptionServicio: Subscription;
  mapaAlerta:any;
  mapaAlertaFinal:any= new Map();
  @ViewChild(MatSort) sortIns: MatSort;
  @ViewChild(MatSort) sortEsc: MatSort;
  /* @ViewChild("fileInput") fileInput: any; */

  file: File;
  public size: number;
  selectedFiles: FileList;
  @Output() public dialogEvent: EventEmitter<any> = new EventEmitter();
  public documentoExternoDTO: DocumentoExternoDTO;
  /*   @ViewChild(MatPaginator) paginator: MatPaginator; */
  ngOnInit() {
    this.rut= this.auth.getAuthInfo()['tokenInfo']['sub'];
     
/*     this.getTerritorio(TipoNivel.LOCAL_VOTACION); */
    this.getTerritorio(TipoNivel.CIRCUNSCRIPCION_ELECTORAL);
    this.subscriptionServicio = interval(20000)   
    .subscribe(x => { 
      this.getData();
      this.getDataAlerta();
    }); 

    
  }

  
  ngOnDestroy(): void {
    this.subscriptionServicio.unsubscribe();
   }

  public territorios = new FormGroup({
    localVotacion: new FormControl('', [Validators.required]),
    ctlPais: new FormControl({value: '', disabled: true},[Validators.required]),
    ctlJunta:new FormControl({value: '', disabled: true}, [Validators.required]),
    ctlCircunscripcion: new FormControl('', [Validators.required])
  });

  get territoriosForm() {
    return this.territorios.controls;
  }
  getData() {
  
    this.actaService.vistaConsul(this.itemLocalVotacion['idTerritorio']).subscribe((resp: any) => {
        
      let dtIns = resp.body['body']['response'] ? this.data = resp.body['body']['data']['constitucionInstalacion'] : [];
      this.dataSourceIns = new MatTableDataSource(dtIns);
      this.lengthIns = dtIns.length;

      let dtEsc = resp.body['body']['response'] ? this.data = resp.body['body']['data']['escrutinioMesas'] : [];

      Object.keys(dtEsc).forEach(key => {
       
        if (dtEsc[key]['codigoLocalVotacion'] == this.itemLocalVotacion['idTerritorio']) {
          dtEsc = dtEsc[key]['mesas'];
        }
      })
         this.dataSourceEsc = new MatTableDataSource(dtEsc);
      this.lengthEsc = dtEsc.length;


      this.dataSourceIns.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };

      this.dataSourceEsc.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };


      setTimeout(() => {
        this.dataSourceIns.sort = this.sortIns;
        this.dataSourceEsc.sort = this.sortEsc;
      }, 500)
    });

  }

  getTerritorio(tipoNivel: TipoNivel) {
    this.territorioService.getTerritorioRutTipo(this.rut, tipoNivel).subscribe(resp => {
      let respuesta = resp;
      if (respuesta) {
        
        let dt: any = respuesta;
        if (tipoNivel == TipoNivel.CIRCUNSCRIPCION_ELECTORAL) {
          this.dtCircunscripcion = dt.body['body']['data'];
          this.itemCircunscripcion = this.dtCircunscripcion[0];
          this.changeCircunscripcion();
        }
      }
    })
  }

  changeCircunscripcion(){
    this.territorioService.getTerritorioByIdTipo(this.itemCircunscripcion.id, TipoNivel.CIRCUNSCRIPCION_ELECTORAL,TipoNivel.LOCAL_VOTACION).subscribe(resp => {
      
      let respuesta = resp.body['body']['response'];
      if (respuesta) {
        this.dtLocalVotacion = resp.body['body']['data'];
        this.itemLocalVotacion = this.dtLocalVotacion[0];
        this.obtieneTitulo();
        this.getData();
        this.getDataAlerta();
      }
    });
  }

  obtieneTitulo() {
    let titulo: string;
    let espacios: string;
    this.actaService.vistaConsulTitulo(this.itemLocalVotacion['idTerritorio']).subscribe(resp => {
      if (resp.body['body']['response']) {
        

        titulo = resp.body['body']['data'];
        this.nombrePais = titulo;
        this.arrStr = titulo.split("/");
        

        this.territoriosForm.ctlPais.setValue(this.arrStr[0]);
        this.territoriosForm.ctlJunta.setValue(this.arrStr[1]);
   
        this.titulosNombres = "Pais" + this.arrStr[0] + "/Junta Electoral" + this.arrStr[1] + "/Consulado" + this.arrStr[2];
  
      }

    });

  }


  crearItem(row: any) {
 
    let nuevo: boolean;
    let dialogRef = this.dialog.open(AdjuntarArchivosComponent, {
      width: '600px',
      data: { data: row }
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData();
      }
    });

  }

  showRechazo(value: any) {
  
    let dialogRef = this.dialog.open(RechazoModalComponent, {
      width: '600px',
      data: { data: value }
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result.body.response) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData();
      }
    });
  }

  getEstadoActa(estado: any) {
    Object.keys(estadoActa).forEach(key => {
      if (key === estado) {
        return estadoActa[key];
      }
    }
    )
    return estadoActa[estado];
  }

  getFile(urlArchivo:string){
    /* console.log("urlArchivo: " + urlArchivo); */
    window.open(urlArchivo + "?idToken=" + localStorage.getItem('access_token'), '_blank');
  }


  private getDataAlerta() {
    this.mapaAlerta = new Map();

    this.actaService.cuentaRegresiva(this.itemLocalVotacion['idTerritorio']).subscribe(resp => {
        if (resp.body['body']['response'] && resp.body['body']['data'] != null) {

            // Proceso de constitución de mesa
            if (resp.body['body']['data']['constitucionMesa'] != null) {
                let objectAlertaCons = {
                    codLocal: this.itemLocalVotacion['idTerritorio'],
                    tiempoAlerta: resp.body['body']['data']['tiempoAlerta'],
                    hoursToDday: resp.body['body']['data']['constitucionMesa']['horas'],
                    minutesToDday: resp.body['body']['data']['constitucionMesa']['minutos'],
                    secondsToDday: resp.body['body']['data']['constitucionMesa']['segundos']
                };
                this.mapaAlerta.set(1, objectAlertaCons);
            }

            // Proceso de instalación de mesa
            if (resp.body['body']['data']['instalacionMesa'] != null) {
                let objectAlertaIns = {
                    codLocal: this.itemLocalVotacion['idTerritorio'],
                    tiempoAlerta: resp.body['body']['data']['tiempoAlerta'],
                    hoursToDday: resp.body['body']['data']['instalacionMesa']['horas'],
                    minutesToDday: resp.body['body']['data']['instalacionMesa']['minutos'],
                    secondsToDday: resp.body['body']['data']['instalacionMesa']['segundos']
                };
                this.mapaAlerta.set(2, objectAlertaIns);
            }

            // Proceso de escrutinio de mesa
            if (resp.body['body']['data']['escrutinioMesa'] != null) {
                let objectAlertaEsc = {
                    codLocal: this.itemLocalVotacion['idTerritorio'],
                    tiempoAlerta: resp.body['body']['data']['tiempoAlerta'],
                    hoursToDday: resp.body['body']['data']['escrutinioMesa']['horas'],
                    minutesToDday: resp.body['body']['data']['escrutinioMesa']['minutos'],
                    secondsToDday: resp.body['body']['data']['escrutinioMesa']['segundos']
                };
                this.mapaAlerta.set(this.itemLocalVotacion['idTerritorio'], objectAlertaEsc);
            }

            this.mapaAlertaFinal = this.mapaAlerta;
            console.log(this.mapaAlertaFinal);
            console.log("-------------------------------------------");
        }
    });
}
}