import { Action } from '@ngrx/store';

export function sidenavReducer(state = false, action:Action){
  
  if(action.type == 'ACTIVO'){
    state = true;
  }else{
    state = false;
  }

  return state;
}
