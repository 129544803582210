import { TextoBusquedaEnum } from './../enums/texto-busqueda.enum';
import { AuthService } from 'src/app/services/auth.service';
import { VistaExport } from './../../models/VistaExport';
import { DatePipe } from '@angular/common'
import { VistaSupervisorExport } from './../../models/VistaSupervisorExport';
import { DocumentoService } from './../../services/documento.service';
import { CountDownComponent } from './../count-down/count-down.component';
import { FiltroAlertaActaComponent } from './filtro-alerta-acta/filtro-alerta-acta.component';
import { FiltroEstadoActaComponent } from './filtro-estado-acta/filtro-estado-acta.component';
import { FiltrosTerritorialesComponent } from './filtros-territoriales/filtros-territoriales.component';
import { ActaService } from './../../services/acta.service';
import { Component, OnInit, Input, ViewChild, Output, ElementRef, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { estadoActa } from 'src/app/component/enums/estado-acta.enum';
import { UtilsService } from '../../services/utils.service';
import { interval, Subscription } from 'rxjs';

import { RechazoModalComponent } from 'src/app/component/modals/rechazo-modal/rechazo-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MessagesService } from 'src/app/services/messages.service';
import { Acta } from '../../models/Acta';
import { filter } from 'minimatch';
import { Mensaje } from 'src/app/models/message';
import { TipoNivel } from '../enums/tipo-nivel.enum';
import { VistaSupervisor } from '../../models/VistaSupervisor';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { InformeEnum } from '../enums/informe-enum';

@Component({
  selector: 'app-vista-administracion',
  templateUrl: './vista-administracion.component.html',
  styleUrls: ['./vista-administracion.component.css']
})
export class VistaAdministracionComponent implements OnInit,OnDestroy {

  @Input() idProceso: number;
  @Input() titulo: string;
  data: any;
  public length: number = 0;
  public dataSource: MatTableDataSource<any>;
  public dataSourceFinal: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscription: any;
  mensaje: '';
  timer: any;
  itemFiltersTerritorio: string;
  itemFiltersEstadoActa: string;
  itemFiltersAlertaActa: string;
  filtroTexto:string;
  mapaAlerta:any;
  mapaAlertaFinal:any= new Map();
  filtersFinal:any;

  private subscriptionServicio: Subscription;
  @ViewChild("filtroTerritorial") filtroTerritorialForm: FiltrosTerritorialesComponent;
  @ViewChild("filtroEstadoActa") filtroEstadoActaForm: FiltroEstadoActaComponent;
  @ViewChild("filtroAlertaActa") filtroAlertaActaForm: FiltroAlertaActaComponent;
  msg: Mensaje;
  public acta: Acta;
  public vistaSupervisor: VistaSupervisor;
  public vistaSupervisorList: VistaSupervisor[] = [];
  public vistaSupervisorExport: VistaSupervisorExport;
  public vistaExport: VistaExport;
  public displayedColumnsIns: string[] = ['continente', 'pais', 'juntaElectoral', 'circunscripcionElectoral', 'localVotacion',
    'mesaDescripcion', 'estado', 'fechaCumplimiento', 'alertaEnvio', 'documento', 'acciones'];
  hiddenMesa: boolean = true;
  hiddenActa: boolean = false;
  hiddenAcciones: boolean= false;
  authInfo:any;
  actualizaAlerta: boolean;
  constructor(public actaService: ActaService, public utils: UtilsService, public dialog: MatDialog,
    public documentoService: DocumentoService, public datepipe: DatePipe, public router: Router,public authService:AuthService) { }


  ngOnInit(): void {
    console.log("entra a vista administracion");
    this.authInfo=this.authService.getAuthInfo()
    //this.mapFiltro= new Map();
    if (this.idProceso == 3) {
      this.hiddenMesa = false;
    }
    if ( this.authInfo.tokenInfo.perfil=='invitado'){
      this.hiddenAcciones=true;
      this.hiddenActa=true;
    }
    this.getData(false);
    this.getDataAlerta();
    this.subscriptionServicio = interval(20000)   
    .subscribe(x => { 
      this.getData(true);
      this.getDataAlerta();
    }); 

 
  }

  ngOnDestroy(): void {
   this.subscriptionServicio.unsubscribe();
  }


  getFilterPredicate() {
  
    return (data, filters: string) => {
      let indexInicio;
      let indexFin;
      let estados: any = [];
      let estadosAlerta: any = [];
      let codigoContinente;
      let codigoPais;
      let codigoJunta;
      let codigoCircunscripcion;
      let codigoLocal;
      let strTexto;
/*          console.log("filters21: " + filters);  
      if (!this.actualizaAlerta){
        if (this.filtersFinal!=undefined){
          filters=this.filtersFinal;
        }
      }else{
        this.filtersFinal=filters;
      } */
 
      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_CONTINENTE);
      if (indexInicio > -1) {
        indexFin = filters.indexOf("|");
       /*  console.log ("index continente fin: " + indexFin); */
        if (indexFin > -1) {
          codigoContinente = filters.substring(indexInicio + 17, indexFin);
          filters = filters.substring(indexFin + 1);
         /*  console.log ("filter continente fin: " + filters); */
        }
      }
      

      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_PAIS);
      if (indexInicio > -1) {
      /*   console.log("entra a pais"); */
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          codigoPais = filters.substring(indexInicio + 11, indexFin);
          filters = filters.substring(indexFin + 1);
        }
      }


      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_JUNTA);
      if (indexInicio > -1) {
      /*   console.log("entra a junta"); */
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          codigoJunta = filters.substring(indexInicio + 12, indexFin);
          filters = filters.substring(indexFin + 1);
        }
      }

      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_CIRCUNSCRIPCION);
      if (indexInicio > -1) {
    /*     console.log("entra a circunscripcion"); */
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          codigoCircunscripcion = filters.substring(indexInicio + 22, indexFin);
          filters = filters.substring(indexFin + 1);
        }
      }

      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_LOCAL);
      if (indexInicio > -1) {
       /*  console.log("entra a local"); */
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          codigoLocal = filters.substring(indexInicio + 12, indexFin);
          filters = filters.substring(indexFin + 1);
        }
      }


      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_ESTADO_ACTA);
      if (indexInicio > -1) {
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          let estadosStr = filters.substring(indexInicio + 12, indexFin).toString().toUpperCase();
          estados = estadosStr.split(",");
          filters = filters.substring(indexFin + 1);
        /*   console.log("filtro Final: " + filters); */
        }
      }



      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_ALERTA_ACTA);
      if (indexInicio > -1) {
        indexFin = filters.indexOf("|");
        if (indexFin > -1) {
          let estadosStr = filters.substring(indexInicio + 12, indexFin).toString().toUpperCase();
          estadosAlerta = estadosStr.split(",");
          filters = filters.substring(indexFin + 1);
        }
      }


      indexInicio = filters.indexOf(TextoBusquedaEnum.CODIGO_TEXTO);

      if (indexInicio > -1) {
        indexFin = filters.indexOf("|");
       /*  console.log("indice fin: " + indexFin); */
        if (indexFin > -1) {
          strTexto = filters.substring(indexInicio + 7, indexFin);
         /*  console.log ("strTexto34:*" + strTexto + "*"); */
          filters = filters.substring(indexFin + 1);
        }
      }
     /*  console.log("strTexto 834:" +strTexto); */

      const matchFilter = [];

      // Fetch data from row
      const columnContinente = data.codContinente;
      const columnNombreContinente = data.continente;
      const columnNombrePais = data.pais;
      const columnNombreJunta = data.juntaElectoral;
      const columnNombreCircuns = data.circunscripcionElectoral;
      const columnNombreLocal = data.localVotacion;
      const columnNombreEstado = this.getEstadoActa(data.estado);
      
      const columnPais = data.codPais;
      const columnJunta = data.codJuntaElectoral;
      const columnCircunscripcion = data.codCircunscripcionElectoral;
      const columnlocal = data.codLocal;




      const columnEstado = data.estado;
      const columnAlerta = data.alertaEnvio;

      const filterContinente: boolean = codigoContinente != undefined ? columnContinente.toString().toLowerCase().includes(codigoContinente) : true;
      const filterPais: boolean = codigoPais != undefined ? columnPais.toString().toLowerCase().includes(codigoPais) : true;
      const filterJunta: boolean = codigoJunta != undefined ? columnJunta.toString().toLowerCase().includes(codigoJunta) : true;
      const filterCircunscripcion: boolean = codigoCircunscripcion != undefined ? columnCircunscripcion.toString().toLowerCase().includes(codigoCircunscripcion) : true;
      const filterLocal: boolean = codigoLocal != undefined ? columnlocal.toString().toLowerCase().includes(codigoLocal) : true;
      const filterTextoBusqueda: boolean = strTexto != undefined ? columnNombreContinente.toString().toLowerCase().includes(strTexto.toString().toLowerCase()) ||
      columnNombrePais.toString().toLowerCase().includes(strTexto.toString().toLowerCase()) ||
      columnNombreJunta.toString().toLowerCase().includes(strTexto.toString().toLowerCase()) ||
      columnNombreCircuns.toString().toLowerCase().includes(strTexto.toString().toLowerCase()) ||
      columnNombreLocal.toString().toLowerCase().includes(strTexto.toString().toLowerCase()) ||
      columnNombreEstado.toString().toLowerCase().includes(strTexto.toString().toLowerCase())  : true;

       /* console.log("filterTextoBusqueda: " + strTexto + "columncontinente: " +  columnNombreContinente.toString().toLowerCase() + "texto: " +strTexto.toString().toLowerCase());   */
      /*  console.log("filterTextoBusqueda: " + filterTextoBusqueda + "strTexto: " + strTexto); */

      if (estados != undefined && estados != "") {
/*         console.log("estados77: " + estados); */
        const filterEstadoActa = estados.length > 0
          ? estados.some(est => est == columnEstado)
          : true;

        matchFilter.push(filterEstadoActa);
      }
     /*  console.log("estados: " + estadosAlerta); */
      if (estadosAlerta != undefined && estadosAlerta != "") {
      /*   console.log("estados77: " + estadosAlerta); */
        const filterEstadoAlerta = estadosAlerta.length > 0
          ? estadosAlerta.some(est => est == columnAlerta)
          : true;

        matchFilter.push(filterEstadoAlerta);
      }

      matchFilter.push(filterContinente);
      matchFilter.push(filterPais);
      matchFilter.push(filterJunta);
      matchFilter.push(filterCircunscripcion);
      matchFilter.push(filterLocal);
      matchFilter.push(filterTextoBusqueda);

      return matchFilter.every(Boolean);
    };
  }

  getTerritoriosFilter(actualizaAlerta:boolean) {

    this.filtroTerritorialForm.territorioOutput.subscribe((territorio) => {
   /*    console.log("entra a territoriosfilter"); */
      var t = JSON.parse(territorio);
     

      if (t[TipoNivel.CONTINENTE] != null) {
        this.itemFiltersTerritorio = TextoBusquedaEnum.CODIGO_CONTINENTE + ":" + t[TipoNivel.CONTINENTE];
      } else if (t[TipoNivel.PAIS] != null) { 
        this.itemFiltersTerritorio = TextoBusquedaEnum.CODIGO_PAIS + ":" + t[TipoNivel.PAIS];
      } else if (t[TipoNivel.JUNTA_ELECTORAL] != null) {
        this.itemFiltersTerritorio = TextoBusquedaEnum.CODIGO_JUNTA + ":" + t[TipoNivel.JUNTA_ELECTORAL];
      } else if (t[TipoNivel.CIRCUNSCRIPCION_ELECTORAL] != null) {
        this.itemFiltersTerritorio = TextoBusquedaEnum.CODIGO_CIRCUNSCRIPCION + ":" + t[TipoNivel.CIRCUNSCRIPCION_ELECTORAL];
      }
    
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    })


    // si es local de votacion hace este filtro
    this.filtroTerritorialForm.localVotacionOutput.subscribe((codigoLocal) => {
      this.itemFiltersTerritorio =  TextoBusquedaEnum.CODIGO_LOCAL + ":" + codigoLocal;
      const filterValue = this.getFilterValues();
  
      this.dataSource.filter = filterValue.trim().toLowerCase();
    })

    if (actualizaAlerta){
    
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getFilterValues() {
    let filterValue: string = "";

    if (this.itemFiltersTerritorio != undefined) {
      filterValue = this.itemFiltersTerritorio + "|";
    }
    if (this.itemFiltersEstadoActa != undefined) {
      if (filterValue != "" && this.itemFiltersTerritorio != undefined) {
        filterValue = filterValue + this.itemFiltersEstadoActa + "|";
      } else {
        filterValue = this.itemFiltersEstadoActa + "|";
      }
    }
    if (this.itemFiltersAlertaActa != undefined) {
      if (filterValue != "" && (this.itemFiltersTerritorio != undefined || this.itemFiltersEstadoActa != undefined)) {
        filterValue = filterValue + this.itemFiltersAlertaActa + "|";
      } else {
        filterValue = this.itemFiltersAlertaActa + "|";
      }


    }
    if (this.filtroTexto != undefined && this.filtroTexto!=""){
      if (filterValue != "" && (this.itemFiltersTerritorio != undefined || this.itemFiltersEstadoActa != undefined || this.itemFiltersAlertaActa != undefined )) {
        filterValue = filterValue + this.filtroTexto + "|";
      } else {
        filterValue = this.filtroTexto + "|";
      }
    }
   /*   console.log("filterValue3434: " + filterValue);  */
    return filterValue;
  }
  getEstadoActaFilter(actualizaAlerta) {
    this.filtroEstadoActaForm.filtroEstadoOutput.subscribe((estado: any[]) => {
    /*    console.log("estado acta: " + estado);  */

      this.itemFiltersEstadoActa =  TextoBusquedaEnum.CODIGO_ESTADO_ACTA + ": " + estado;
      // console.log("estado acta filtro: " + this.itemFiltersEstadoActa);
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    })  
    if (actualizaAlerta){
     
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getEstadoAlertaFilter(actualizaAlerta) {

    this.filtroAlertaActaForm.filtroEstadoAlertaOutput.subscribe((estado: any) => {
 
      if (estado.includes("ATRASADO")){
        this.itemFiltersEstadoActa = TextoBusquedaEnum.CODIGO_ESTADO_ACTA + ": NO_INGRESADA,RECHAZADA";
    
      }
      this.itemFiltersAlertaActa =  TextoBusquedaEnum.CODIGO_ALERTA_ACTA + ": " + estado;
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    })
    if (actualizaAlerta){
   
      const filterValue = this.getFilterValues();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }


  applyFilter(value: string) {
 
 if (value.trim()!=""){
 this.filtroTexto = TextoBusquedaEnum.CODIGO_TEXTO + ": " + value;
    
    const filterValue = this.getFilterValues();
    
    this.dataSource.filter = filterValue;
 }else{
  this.filtroTexto ="";
  const filterValue = this.getFilterValues();
    
    this.dataSource.filter = filterValue;
 }
  }

  estadoAlerta(val: any, row: any) {
  /*   console.log("stado alerta:" + val); */
    row.alertaEnvio = val;
  }



  getData(actualizaAlerta:boolean) {

    this.actualizaAlerta=actualizaAlerta;
 /*    console.log("get dataaaa"); */

    this.actaService.vistaAdministrador(this.idProceso).subscribe((resp: any) => {
/*        */

      let dt = resp.body['body']['response'] ? this.data = resp.body['body']['data'] : [];
     
      this.dataSource = new MatTableDataSource(dt);
      this.length = dt.length;


      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };


      setTimeout(() => {
        this.filtroTerritorialForm.limpiaOutput.subscribe((resp) => {
          let index: number = this.dataSource.filter.indexOf(TextoBusquedaEnum.CODIGO_ESTADO_ACTA);
          if (index > -1) {
            this.dataSource.filter = this.dataSource.filter.substring(index);
          } else {
            index = this.dataSource.filter.indexOf(TextoBusquedaEnum.CODIGO_ALERTA_ACTA);
            if (index > -1) {
              this.dataSource.filter = this.dataSource.filter.substring(index);
            } else {
      /*         console.log("limpia filtro 34: " + this.dataSource.filter); */
              this.dataSource.filter = "";
          
              this.itemFiltersTerritorio=undefined;
            }
          }
        })
        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        
        /* if (!actualizaAlerta){ */
   
        this.dataSource.filterPredicate = this.getFilterPredicate();
        /* } */
        /* if (!actualizaAlerta){ */
        this.getTerritoriosFilter(actualizaAlerta);
   /*      } */
        this.getEstadoActaFilter(actualizaAlerta);
        this.getEstadoAlertaFilter(actualizaAlerta);
        this.dataSourceFinal=this.dataSource;
      }, 0)
    });

  }


  getEstadoActa(estado: any) {
    Object.keys(estadoActa).forEach(key => {
      if (key === estado) {
        return estadoActa[key];
      }
    }
    )
    return estadoActa[estado];
  }

  setRechazo(value: any) {
    this.callRechazo(value, true, true);
  }

  callRechazo(value: any, rechazo, nuevo) {
   /*  console.log("rechazo: " + JSON.stringify(value)); */
    let dialogRef = this.dialog.open(RechazoModalComponent, {
      width: '600px',
      data: { data: value, rechazo: rechazo, nuevo: nuevo }
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData(false);
        this.dataSource.filter="";
        this.limpiarFiltros();
      }
    });
  }


  limpiarFiltros(){
    this.filtroTerritorialForm.limpiar();
    this.filtroEstadoActaForm.limpiar();
    this.filtroAlertaActaForm.limpiar();
    this.filtroTexto ="";
/*     this.itemFiltersTerritorio=""; */
  }

  showRechazo(value: any) {
    this.callRechazo(value, false, false);
  }

  /* sendMsg(local: number, mensaje: string) {
    this.msg = new Mensaje();
    this.msg.tipoMensaje = mensaje;
    this.msg.codigoLocal = local;
   
    this.messagesService.messages.next(this.msg);

  } */

  aprobar(value: any) {
    let acta = new Acta();
    acta.idActa = value.idActa;

    acta.motivoRechazo = null;
    this.utils.mDialog("Confirmación", "¿Está seguro de confirmar la acción?", "confirm").subscribe(r => {

      if (r) {
        let key = Object.keys(estadoActa).find(x => estadoActa[x] == estadoActa.APROBADA);
        acta.estado = key;

        this.actaService.actualizaActa(acta).subscribe(resp => {
          if (resp.body['body']['response']) {
            
            this.getData(false);
            //this.sendMsg(value.codLocal, "aprueba");
            this.limpiarFiltros();
          }
        })
      }
    });

  }

  setNoInstalada(value: any) {
    this.acta = new Acta();
   /*  console.log("acta: " + JSON.stringify(value)); */
    this.acta.idActa = value.idActa;
    this.utils.mDialog("Confirmación", "¿Está seguro de confirmar la acción?", "confirm").subscribe(r => {
      if (r) {
        let key = Object.keys(estadoActa).find(x => estadoActa[x] == estadoActa.NO_INSTALADA);
        this.acta.estado = key;
        this.actaService.actualizaActa(this.acta).subscribe(resp => {
          if (resp) {
            
            this.getData(false);
          //  this.sendMsg(value.codLocal, "noinstalada");
            this.limpiarFiltros();
          } else {
            this.utils.mDialog("Error", "Se ha producido un error. Intente más tarde", "message");
          }
        });
      }
    });
  }

  exportarExcel(ds: any) {
    /* console.log("data1.." + JSON.stringify(this.dataSource.filteredData)); */


    this.vistaSupervisorList = [];
    Object.keys(this.dataSource.filteredData).forEach(key => {
      this.vistaSupervisor = new VistaSupervisor();
      this.vistaSupervisor.continente = this.dataSource.filteredData[key]["continente"];
      this.vistaSupervisor.pais = this.dataSource.filteredData[key]["pais"];
      this.vistaSupervisor.juntaElectoral = this.dataSource.filteredData[key]["juntaElectoral"];
      this.vistaSupervisor.circunscripcionElectoral = this.dataSource.filteredData[key]["circunscripcionElectoral"];
      this.vistaSupervisor.localVotacion = this.dataSource.filteredData[key]["localVotacion"];
      this.vistaSupervisor.mesa = this.dataSource.filteredData[key]["mesaDescripcion"];
      this.vistaSupervisor.estado = this.getEstadoActa(this.dataSource.filteredData[key]["estado"]);
      let latest_date = this.datepipe.transform(this.dataSource.filteredData[key]["fechaCumplimiento"], 'dd/MM/yyyy HH:mm');
      this.vistaSupervisor.fechaCumplimiento = latest_date;
      this.vistaSupervisor.codLocal = this.dataSource.filteredData[key]["codLocal"];
      this.vistaSupervisorList.push(this.vistaSupervisor);
    });
    this.vistaSupervisorExport = new VistaSupervisorExport();
    this.vistaSupervisorExport.idProceso = this.idProceso;
    this.vistaSupervisorExport.vistaSupervisor = this.vistaSupervisorList;

    this.vistaExport= new VistaExport();
    this.vistaExport.vistaSupervisorExport=this.vistaSupervisorExport;
    this.vistaExport.informeEnum=InformeEnum.INFORME_ACTA;


   /*  console.log("exporta: " + JSON.stringify(this.vistaSupervisorExport.vistaSupervisor)); */
    this.documentoService.generateCsv(this.vistaExport).subscribe((resp) => {
      if (resp.body['body']['response']) {
        
        this.utils.downloadFileCsv({
          name: resp.body['body']['data']['nombreArchivo'],
          file64: resp.body['body']['data']['fileData'],
          mimetype: 'application/vnd.ms-excel'
        })

      }else{
        this.utils.message("No se puede generar el documento. Intente más tarde.")
      }

    });
    //console.log(JSON.stringify(this.dataSource.data[0]["continente"]));
    //this.vistaSupervisor.continente=this.dataSource[]


  }


  vistaResumen() {
    this.router.navigate(["continente-resumen"],{queryParams: {idProceso: this.idProceso,idTerritorio: 0,tipoTerritorio: 'CONT'}});
  }

  
  getFile(urlArchivo:string){
    /* console.log("urlArchivo: " + urlArchivo); */
    window.open(urlArchivo + "?idToken=" + localStorage.getItem('access_token'), '_blank');
  }

  private getDataAlerta() {
    this.mapaAlerta=new Map();
    this.actaService.cuentaRegresivaAll().subscribe(resp => {
      if (resp.body['body']['response'] && resp.body['body']['data'] != null) {
        
        Object.keys(resp.body['body']['data']).forEach(key => {
          let objectAlerta = new Object();
          objectAlerta['tiempoAlerta'] = resp.body['body']['data'][key]['tiempoAlerta'];
          objectAlerta['codLocal'] = resp.body['body']['data'][key]['codLocal'];
          /* console.log("respuesta: " + JSON.stringify(resp['body']['data'])); */
          if (this.idProceso == 1) {
            if (resp.body['body']['data'][key]['constitucionMesa'] != null) {
              objectAlerta['minutesToDday'] = resp.body['body']['data'][key]['constitucionMesa']['minutos'];
              objectAlerta['hoursToDday'] = resp.body['body']['data'][key]['constitucionMesa']['horas'];
              objectAlerta['secondsToDday'] = resp.body['body']['data'][key]['constitucionMesa']['segundos'];
              this.mapaAlerta.set( resp.body['body']['data'][key]['codLocal'],objectAlerta);
            }
          } else if (this.idProceso == 2) {
            if (resp.body['body']['data'][key]['instalacionMesa'] != null) {
              objectAlerta['minutesToDday'] = resp.body['body']['data'][key]['instalacionMesa']['minutos'];
              objectAlerta['hoursToDday'] = resp.body['body']['data'][key]['instalacionMesa']['horas'];
              objectAlerta['secondsToDday'] = resp.body['body']['data'][key]['instalacionMesa']['segundos'];

              this.mapaAlerta.set( resp.body['body']['data'][key]['codLocal'],objectAlerta);

            }

          } else if (this.idProceso == 3) {
            if (resp.body['body']['data'][key]['escrutinioMesa'] != null) {
              objectAlerta['minutesToDday'] = resp.body['body']['data'][key]['escrutinioMesa']['minutos'];
              objectAlerta['hoursToDday'] = resp.body['body']['data'][key]['escrutinioMesa']['horas'];
              objectAlerta['secondsToDday'] = resp.body['body']['data'][key]['escrutinioMesa']['segundos'];
              this.mapaAlerta.set( resp.body['body']['data'][key]['codLocal'],objectAlerta);
            }
          }
        })
        this.mapaAlertaFinal=this.mapaAlerta;
        console.log("MAPA ALERTA: " + JSON.stringify(this.mapaAlertaFinal));
    
      }
    });
}


}
