<div *ngIf="this.dataSource && this.mapaAlertaFinal">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
        <div fxFlex="15%">
            <div class="btn-section-filtro">
                <app-filtros-territoriales [limpiaFiltros]="true" #filtroTerritorial></app-filtros-territoriales>
            </div>
        </div>
        <div fxFlex="83%">
            <h1 class="display-5 title-app btn-section"> {{titulo}}</h1>
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
                <div fxFlex="49%">
                    <app-filtro-estado-acta [idProceso]="this.idProceso" #filtroEstadoActa></app-filtro-estado-acta>
                </div>
                <div fxFlex="48%">
                    <app-filtro-alerta-acta [idProceso]="this.idProceso" #filtroAlertaActa></app-filtro-alerta-acta>
                </div>


            </div>
            <div fxLayout="row wrap"  fxLayout.xs="column" fxLayoutGap="1%">
                <div fxFlex="100%">
                    <div class="btn-section">
                        <button mat-button mat-raised-button class="bg-bluedef boton-vista-resumen" (click)="vistaResumen()">Resumen</button>
                        <button mat-button mat-raised-button class="bg-green boton-vista-exp" (click)="exportarExcel(dataSource)">Exportar Excel</button>
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
                <div fxFlex="100%">
                    <mat-form-field class="full">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Búsqueda Avanzada">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="mat-elevation-z8">
                <mat-table [dataSource]="dataSourceFinal" matSort>
                    <ng-container matColumnDef="continente">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Continente</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.continente}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="pais">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Pais</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.pais}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="juntaElectoral">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Junta Electoral</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.juntaElectoral}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="circunscripcionElectoral">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Circunscripcion Electoral</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.circunscripcionElectoral}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="localVotacion">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Local de Votación</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.localVotacion}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mesaDescripcion">
                        <mat-header-cell [hidden]="hiddenMesa" *matHeaderCellDef mat-sort-header>Mesa</mat-header-cell>
                        <mat-cell *matCellDef="let row" [hidden]="hiddenMesa"> {{row.mesaDescripcion}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Estado</mat-header-cell>

                        <mat-cell *matCellDef="let row" [ngClass]="{'color_rechazada': row.estado=='RECHAZADA'}">{{getEstadoActa(row.estado)}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaCumplimiento">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Cumplimiento</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.fechaCumplimiento| date:'dd/MM/yyyy HH:mm'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="alertaEnvio">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Alerta Envío</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div *ngIf="row.estado != 'APROBADA' && row.estado != 'NO_INSTALADA' && this.mapaAlertaFinal.get(row.codLocal)!=undefined">
                                <app-count-down [idProceso]="this.idProceso" [codigoLocal]="row.codLocal" [estado]="row.estado" [estadoAlerta]="row.alertaEnvio" [minutesToDday]="this.mapaAlertaFinal.get(row.codLocal).minutesToDday" [hoursToDday]="this.mapaAlertaFinal.get(row.codLocal).hoursToDday" [secondsToDday]="this.mapaAlertaFinal.get(row.codLocal).secondsToDday"></app-count-down>
                                
                                <!-- local: {{row.codLocal}} json: {{this.mapaAlertaFinal.get(row.codLocal) | json}} horas: {{this.getHoras(row.codLocal)}} min: {{this.getMinutos(row.codLocal)}}  -->
<!--                                 <app-count-down [idProceso]="this.idProceso" [codigoLocal]="row.codLocal" [estado]="row.estado" [estadoAlerta]="row.alertaEnvio" [minutesToDday]="this.mapaAlertaFinal.get('codLocal')"></app-count-down> -->
                            </div>
                            <div *ngIf="row.estado == 'APROBADA'">
                                <i class="fas fa-check-double fa-lg fa-fw"></i>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="documento">
                        <mat-header-cell *matHeaderCellDef [hidden]="hiddenActa" mat-sort-header>Acta Adjunta</mat-header-cell>
                        <mat-cell *matCellDef="let row" [hidden]="hiddenActa">
                            <span *ngIf="row.documento!=null && this.authInfo.tokenInfo.perfil=='administrador'">
                                <a (click)="getFile(row.documento.linkExterno)" target="_blank"   >
                                        <i class="far fa-file-pdf fa-3x"></i>
                                </a>    
                            </span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="acciones">
                        <mat-header-cell *matHeaderCellDef [hidden]="hiddenAcciones">Acciones</mat-header-cell>
                        <mat-cell *matCellDef="let row" [hidden]="hiddenAcciones" data-label="Acciones">

                            <div *ngIf="!this.sortItems">
                                <div *ngIf="row.estado!='NO_INGRESADA' && row.estado!='APROBADA' && row.estado!='NO_INSTALADA' && (this.idProceso==2 || this.idProceso==1)">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones" matTooltipPosition="right">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="row.existeActaInstalacion && row.estado!='APROBADA' && row.estado!='NO_INSTALADA' && this.idProceso==3">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones" matTooltipPosition="right">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                </div>
                                <mat-menu #menu="matMenu">
                                    <div *ngIf="row.estado!='NO_INGRESADA' && row.estado!='APROBADA' && row.estado!='RECHAZADA' && this.authInfo.tokenInfo.perfil=='administrador'">
                                        <button mat-menu-item (click)="aprobar(row)">
                                            <!-- <mat-icon>create</mat-icon> -->
                                            <i class="fas fa-check-circle  fa-lg fa-fw"></i>
                                            <span>Aprobar</span>
                                        </button>
                                    </div>

                                    <div *ngIf="row.estado!='NO_INGRESADA' && row.estado!='RECHAZADA' && row.estado!='APROBADA' && this.authInfo.tokenInfo.perfil=='administrador'">
                                        <button mat-menu-item (click)="setRechazo(row)">
                                            <i class="fas fa-times  fa-lg fa-fw"></i>
                                            <!-- <i class="far fa-eye fa-lg fa-fw"></i> -->
                                            <span>Rechazar</span>
                                        </button>
                                    </div>

                                    <div *ngIf="row.estado=='RECHAZADA' || row.motivoRechazo!=undefined">
                                        <button mat-menu-item (click)="showRechazo(row)">
                                            <!-- <mat-icon>create</mat-icon> -->
                                            <i class="far fa-eye fa-lg fa-fw"></i>
                                            <span>Ver rechazo</span>
                                        </button>
                                    </div>

                                    <div *ngIf="row.existeActaInstalacion && this.idProceso==3 && this.authInfo.tokenInfo.perfil=='administrador'">
                                        <button mat-menu-item (click)="setNoInstalada(row)">
                                            <!-- <mat-icon>create</mat-icon> -->
                                            <i class="fas fa-user-times  fa-lg fa-fw"></i>

                                            <span>No instalada</span>
                                        </button>
                                    </div>

                                </mat-menu>
                            </div>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsIns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsIns;"></mat-row>
                </mat-table>
                <div *ngIf="length == 0" class="noresults">No se encontraron resultados</div>
                <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons>
                </mat-paginator>
            </div>

        </div>
    </div>
</div>