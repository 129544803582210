<mat-card>
    <mat-card-title>LOGIN</mat-card-title>
<mat-card-content>
<form [formGroup]="form" (ngSubmit)="login()">
  <p>
    <mat-form-field>
      <input type="text" matInput placeholder="Username" formControlName="rut">
      <mat-hint align="end">Ingrese su rut sin puntos con guión ejemplo 1234567-8</mat-hint>
    </mat-form-field>
    
  </p>
  <div *ngIf="!this.primerLogin">
  <p>
    <mat-form-field>
      <input type="password" matInput placeholder="Password" formControlName="password">
    </mat-form-field>
  </p>
</div>
  
  <div *ngIf="this.primerLogin">
    <p>
      <mat-form-field>
        <input type="password" matInput placeholder="Nuevo password" formControlName="passwordNew">
      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <input type="password" matInput placeholder="Reingrese password" formControlName="passwordNew2">
      </mat-form-field>
    </p>
  </div>


  <p *ngIf="error" class="error">
    {{ error }}
  </p>

  <div class="button">
    <button type="submit" mat-button>Login</button>
  </div>




</form>
</mat-card-content>
</mat-card>
