import { environment } from './../../environments/environment';
import { Injectable } from "@angular/core";
import { WebsocketService } from "./websocket.service";
/* import { Subject,Observable } from 'rxjs'; */
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';

 export interface Mensajes {

	tipoMensaje: string;
	codigoProceso: number;
	codigoLocal:number;
} 

@Injectable()
export class MessagesService {
  public messages: Subject<Mensajes>;

  constructor(wsService: WebsocketService,auth:AuthService) {
		this.messages = <Subject<Mensajes>>wsService
		//.connect(environment.ws + "/mensajes/" + localStorage.getItem('access_token'))
		    .connect(environment.ws + "/mensajesws/" + localStorage.getItem('access_token'))
			.pipe(map((response: MessageEvent): Mensajes => {
        let data = JSON.parse(response.data);
				return {
					/* author: data.author, */
					tipoMensaje: data.tipoMensaje,
					codigoProceso: data.codigoProceso,
					codigoLocal:data.codigoLocal

				}
			}));
	}
}