import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from 'src/app/component/modals/message/message.component';
import { Funcionalidades } from 'src/app/component/enums/funcionalidades.enum';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public progressBar: boolean = false;
  public horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  public updateSession: EventEmitter<boolean> = new EventEmitter;
  public verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  uploadActa: EventEmitter<number> = new EventEmitter();
  public configObservable = new Subject<number>();
  public breadcrumbs: any = {};
  public breadCrumbsNav: any[]=[];
  constructor(public dialog: MatDialog,    public snackBar: MatSnackBar) { }

  mDialog(title: string, message: string, type: string) {
    let dialogConfirm = this.dialog.open(MessageComponent, {
      width: '400px',
      data: { title: title, message: message, type: type },
      autoFocus: false
    });

    return dialogConfirm.afterClosed();
  }

  showProgressBar() {
    this.progressBar = true;
  }

  hideProgressBar() {
    this.progressBar = false;
  }

  message(message: string): void {
    this.snackBar.open(message, 'Cerrar', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: "my-snackbar"

    });
  }
  updateSessionInfo() {
    this.updateSession.emit(true);
  }


  emitConfig(val) {
    this.configObservable.next(val);
  }

  uploadActaEvent(number){
   
    this.uploadActa.emit(number);
  }
  getUploadEmmiter(){
   
    return this.uploadActa;
  }
  
  getFuncionalidadesRoute(mapMenu:any) {

    return [
      {
        groupName: 'Home',
        groupEnable: false,
        groupItems: [
          { itemName: 'Home', route: '/home',icon: 'fas fa-home fa-lg fa-fw', fnCode: Funcionalidades.HOME, enable: false }
        ]
      },
      {
        groupName: 'Zona Horaria',
        groupEnable: false,
        groupItems: [
          { itemName: 'Zona Horaria', route: '/zona-horaria',icon: 'fas fa-user-cog fa-lg fa-fw', fnCode: Funcionalidades.ZONA, enable: false }
        ]
      },
      {
        groupName: 'Usuarios',
        groupEnable: false,
        groupItems: [
          { itemName: 'Usuarios', route: '/usuarios',icon: 'fas fa-users fa-lg fa-fw', fnCode: Funcionalidades.USUARIOS, enable: false }
        ]
      },
      {
        groupName: 'Resumen Cónsul',
        groupEnable: false,
        groupItems: [
          { itemName: 'Resumen Cónsul', route: '/consul',icon: 'fas fa-landmark fa-lg fa-fw', fnCode: Funcionalidades.RESUMEN_CONSUL, enable: false }
        ]
      },
      {
        groupName: 'Constitución de Mesas',
        groupEnable: false,
        groupItems: [
          { itemName: 'Constitución de Mesas', route: '/constitucion-mesas',icon:'fas fa-building fa-lg fa-fw', fnCode: Funcionalidades.CONSTITUCION_MESAS, enable: false }
        ]
      },
      {
        groupName: 'Instalación de Mesas',
        groupEnable: false,
        groupItems: [
          { itemName: 'Instalación de Mesas', route: '/instalacion-mesas',icon: 'fas fa-book-reader fa-lg fa-fw', fnCode: Funcionalidades.INSTALACION_MESAS, enable: false }
        ]
      },
      {
        groupName: 'Escrutinio de Mesas',
        groupEnable: false,
        groupItems: [
          { itemName: 'Escrutinio de Mesas', route: '/escrutinio-mesas',icon: 'fas fa-vote-yea fa-lg fa-fw', fnCode: Funcionalidades.ESCRUTINIO_MESAS, enable: false }
        ]
      }
    ];
  }

  downloadFile(file: any, name?: string) {

    let arrb: any = this.base64ToArrayBuffer(file.file64 ? file.file64 : file.fileData);
    let data: Blob = new Blob([arrb], { type: file.mimetype ? file.mimetype : 'application/pdf' + ";charset=UTF-8" });
    if (name)
      FileSaver.saveAs(data, name);
    else
      FileSaver.saveAs(data, file.name);
  }

  downloadFileCsv(file: any, name?: string) {
/*     console.log("file: " + JSON.stringify(file) + "name: " + name); */
    let arrb: any = this.base64ToArrayBuffer(file.file64 ? file.file64 : file.fileData);
    let data: Blob  = new Blob(["\ufeff", arrb]) ;
    //let data: Blob = new Blob([arrb], { type: 'text/csv;charset:utf-8,%EF%BB%BF;' });
    if (name)
      FileSaver.saveAs(data, name);
    else
      FileSaver.saveAs(data, file.name);
  }
  base64ToArrayBuffer(base64: string) {
/*     console.log ("base64" + base64); */
    var binary_string = window.atob(base64.trim());
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  
  setBreadcrumbs(brdc: any) {
    this.breadcrumbs = brdc;
  }
}
