import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { estadoActa } from 'src/app/component/enums/estado-acta.enum';
import { Item } from 'src/app/models/Item';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filtro-estado-acta',
  templateUrl: './filtro-estado-acta.component.html',
  styleUrls: ['./filtro-estado-acta.component.css']
})
export class FiltroEstadoActaComponent implements OnInit {
  dtEstados: any = [];
  dtEstadosFiltro: any = [];
  itemEstado: any;
  item: Item;
  @Input() idProceso: number;
  
  @Output() public filtroEstadoOutput: EventEmitter<any> = new EventEmitter();
  itemControl = new FormControl();
  constructor() { }

  ngOnInit(): void {
    this.getEstadoActa();
  }
  getEstadoActa() {
    this.dtEstados = [];
    Object.keys(estadoActa).forEach(key => {
   
      if ((this.idProceso == 1 && key.toString() == "NO_INSTALADA") || (this.idProceso == 2 && key.toString() == "NO_INSTALADA")) {
      }else{
        this.item = new Item();
        this.item.codigo = key.toString();
        this.item.nombre = estadoActa[key];
        this.dtEstados.push(this.item);
      }
    }
    )
  }

  setEstado(event: any, val: any) {
    if (event.checked) {
      this.dtEstadosFiltro.push(val.codigo);
    } else {
      this.dtEstadosFiltro.splice(val.codigo, 1);
    }


    this.filtroEstadoOutput.emit(this.dtEstadosFiltro);
  }

  limpiar(){
    this.itemControl.setValue(false);
    this.dtEstadosFiltro=[];
  }
}
