
<div *ngIf="auth.isAuth() && this.menuItems">
    <mat-nav-list>
      <ng-container *ngFor="let mit of menuItems">
          <ng-container *ngFor="let item of mit.groupItems">
          <a mat-list-item routerLink="{{item.route}}" style="padding-left: 10px"  *ngIf="item.enable" >
            <i class="{{item.icon}}"  ></i>{{item.itemName}}</a>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </div>