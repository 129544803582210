import { DocumentoService } from './../../services/documento.service';
import { VistaExport } from 'src/app/models/VistaExport';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TerritorioService } from 'src/app/services/territorio.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { CreaZonaComponent } from 'src/app/pages/zona-horaria/crea-zona/crea-zona.component';
import { UtilsService } from 'src/app/services/utils.service';
import { VistaZona } from 'src/app/models/VistaZona';
import { InformeEnum } from 'src/app/component/enums/informe-enum';

@Component({
  selector: 'app-zona-horaria',
  templateUrl: './zona-horaria.component.html',
  styleUrls: ['./zona-horaria.component.css']
})
export class ZonaHorariaComponent implements OnInit {
  public dataSource:MatTableDataSource<any>;
  public length:number = 0;
  vistaZonaList: VistaZona[]=[];
  vistaZona: VistaZona;
  vistaExport: VistaExport;
  constructor(public territorioService:TerritorioService,public dialog: MatDialog,public utils: UtilsService,public documentosService:DocumentoService) { }
  public displayedColumns: string[] = ['continente','pais','juntaElectoral','circunscripcionElectoral','localVotacion','zonaHoraria','acciones'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.territorioService.getTerritorioLocales().subscribe((data:any) =>{
      //this.paises= resp.data;

      let dt:any = (data.body['body']['data']) ? data.body['body']['data'] : [];
     
      
      //console.log("datos body: " + JSON.stringify(data['body']['data']));
      this.dataSource = new MatTableDataSource(dt);
      //console.log("datos body2: " + JSON.stringify(this.dataSource));
      this.length = dt.length;

      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };
       
        setTimeout( () =>{
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },500)
      });

  }

  crearItem(data: any) {
  
    let dialogRef = this.dialog.open(CreaZonaComponent, {
      width: '600px',
      data: { data: data }
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData();
      }
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.data.forEach(element => {
      for (const key in element) {
        if (!element[key] || element[key] === null) {
          element[key] = '';
        }
      }
    });
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportarExcel(ds: any) {
 

    this.vistaZonaList = [];
    Object.keys(this.dataSource.filteredData).forEach(key => {
      this.vistaZona = new VistaZona();
      this.vistaZona.continente = this.dataSource.filteredData[key]["continente"];
      this.vistaZona.pais = this.dataSource.filteredData[key]["pais"];
      this.vistaZona.juntaElectoral = this.dataSource.filteredData[key]["juntaElectoral"];
      this.vistaZona.circunscripcionElectoral = this.dataSource.filteredData[key]["circunscripcionElectoral"];
      this.vistaZona.localVotacion = this.dataSource.filteredData[key]["localVotacion"];
      this.vistaZona.zonaHoraria = this.dataSource.filteredData[key]["zonaHoraria"];
      this.vistaZonaList.push(this.vistaZona);
    });

    this.vistaExport= new VistaExport();
    this.vistaExport.vistaZona=this.vistaZonaList;
    this.vistaExport.informeEnum=InformeEnum.INFORME_ZONA;


    
    this.documentosService.generateCsv(this.vistaExport).subscribe((resp) => {
      if (resp.body['body']['response']) {
        
        this.utils.downloadFileCsv({
          name: resp.body['body']['data']['nombreArchivo'],
          file64: resp.body['body']['data']['fileData'],
          mimetype: 'application/vnd.ms-excel'
        })

      }else{
        this.utils.message("No se puede generar el documento. Intente más tarde.")
      }

    });
    //console.log(JSON.stringify(this.dataSource.data[0]["continente"]));
    //this.vistaSupervisor.continente=this.dataSource[]


  }

}
