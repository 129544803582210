import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-resumen',
  templateUrl: './local-resumen.component.html',
  styleUrls: ['./local-resumen.component.css']
})
export class LocalResumenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
