import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circunscripcion-resumen',
  templateUrl: './circunscripcion-resumen.component.html',
  styleUrls: ['./circunscripcion-resumen.component.css']
})
export class CircunscripcionResumenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
