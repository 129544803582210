
import { VistaExport } from './../../models/VistaExport';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { Acta } from './../../models/Acta';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActaService } from 'src/app/services/acta.service';
import { TipoNivel } from '../../component/enums/tipo-nivel.enum';
import { DocumentoService } from '../../services/documento.service';
import { InformeEnum } from 'src/app/component/enums/informe-enum';
import { VistaResumen } from 'src/app/models/VistaResumen';
import { VistaResumenExport } from 'src/app/models/VistaResumenExport';

@Component({
  selector: 'app-vista-resumen',
  templateUrl: './vista-resumen.component.html',
  styleUrls: ['./vista-resumen.component.css']
})
export class VistaResumenComponent implements OnInit {
  public dataSource: MatTableDataSource<any>;
  public length: number = 0;
  public idProceso:number=0;
  public idTerritorio:number=0;
  public tipoTerritorio:string;
  private titulo: string;
  @Input() rLink: string;
  vistaExport:VistaExport;
  vistaResumen:VistaResumen;
  vistaResumenList: VistaResumen[] = [];
  vistaResumenExport:VistaResumenExport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns: string[] = ['territorio', 'totalActas', 'actasAprobadas', 'porcentaje', 'noIniciado', 'enProceso', 'atrasado'];
  constructor(private actaService: ActaService, private utils:UtilsService,private route:ActivatedRoute,private router: Router,private documentoService:DocumentoService) { 


  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
   
      this.idProceso = params['idProceso'];
      let existeValor:boolean=false;

      if (this.idProceso==1){
        this.titulo= "Resumen Constitución de Mesas"
      }else if (this.idProceso==2){
        this.titulo= "Resumen Instalación de Mesas"
      }else if (this.idProceso==3){
        this.titulo= "Resumen Escrutinio de Mesas"
      }
      this.idTerritorio = params['idTerritorio'];
      this.tipoTerritorio= params['tipoTerritorio'];
      
      if (this.utils.breadCrumbsNav.length>0){
      if (this.rLink=="/pais-resumen"){
        this.utils.breadCrumbsNav=[];
        this.utils.breadCrumbsNav.push({ n: 'Continentes', r: '/continente-resumen', q:{idProceso: this.idProceso,idTerritorio: 0,tipoTerritorio:TipoNivel.CONTINENTE}});
      }else if (this.rLink=="/junta-resumen"){
        existeValor=this.buscaCrumbs();
        if (!existeValor){
        this.utils.breadCrumbsNav.push({ n: 'Paises', r: '/pais-resumen', q:{idProceso: this.idProceso,idTerritorio: this.idTerritorio,tipoTerritorio: TipoNivel.CONTINENTE}});
        }
      }else if (this.rLink=="/circunscripcion-resumen"){
        existeValor=this.buscaCrumbs();
        if (!existeValor){
        this.utils.breadCrumbsNav.push( { n: 'Junta Electoral', r: '/junta-resumen', q:{idProceso: this.idProceso,idTerritorio: this.idTerritorio,tipoTerritorio: TipoNivel.PAIS}});
        }
      }else if (this.rLink=="/local-resumen"){
        existeValor=this.buscaCrumbs();
        if (!existeValor){
        this.utils.breadCrumbsNav.push( { n: 'Circunscripción Electoral', r: '/circunscripcion-resumen', q:{idProceso: this.idProceso,idTerritorio: this.idTerritorio,tipoTerritorio: TipoNivel.JUNTA_ELECTORAL}});
        }
      }else{
        existeValor=this.buscaCrumbs();
        if (!existeValor){
        this.utils.breadCrumbsNav.push( { n: 'Local Electoral', r: '/local-resumen', q:{idProceso: this.idProceso,idTerritorio: this.idTerritorio,tipoTerritorio: TipoNivel.CIRCUNSCRIPCION_ELECTORAL}});
        }
      }
    }else{
      this.utils.breadCrumbsNav.push({ n: 'Continentes', r: '/continente-resumen', q:{idProceso: this.idProceso,idTerritorio: 0,tipoTerritorio:TipoNivel.CONTINENTE}});
      this.router.navigate(["continente-resumen"],{queryParams: {idProceso: this.idProceso,idTerritorio: 0,tipoTerritorio: 'CONT'}});
    }
   
      
      this.utils.setBreadcrumbs(
        {
          t: this.titulo,
          b: this.utils.breadCrumbsNav,/*  [
            { n: 'Continentes', r: '/continente-resumen', q:{idProceso: this.idProceso}  }, */
  /*           { n: 'Menú', r: '/menu' } */
         /*  ] */
        })

      this.getData();
      
    });

   
  }

  getData() {
    if (this.idProceso)
    this.actaService.getResumenActa(this.tipoTerritorio, this.idTerritorio, this.idProceso).subscribe(resp => {
      
      let dt:any = (resp.body['body']['data']) ? resp.body['body']['data'] : [];
      //console.log("datos body: " + JSON.stringify(data['body']['data']));
      this.dataSource = new MatTableDataSource(dt);
      //console.log("datos body2: " + JSON.stringify(this.dataSource));
      this.length = dt.length;

      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };
       
        setTimeout( () =>{
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },500)

    })

  }

  buscaCrumbs(){
    let existeValor:boolean=false;
    let keyElimina:number;
    let registrosElimina:number;
  
    Object.keys(this.utils.breadCrumbsNav).forEach(key =>{
      if (this.utils.breadCrumbsNav[key]['r']==this.rLink){
       
        //this.utils.breadCrumbsNav.splice(parseInt(key),this.utils.breadCrumbsNav.length-parseInt(key));
        keyElimina=parseInt(key);
        registrosElimina=this.utils.breadCrumbsNav.length-parseInt(key);
        existeValor= true;
      }
     
     /*  if (parseInt(key)>parseInt(keyElimina)){
        this.utils.breadCrumbsNav.splice(parseInt(key),this.utils.breadCrumbsNav.length-parseInt(key));
      } */
    })
    if (existeValor){
      this.utils.breadCrumbsNav.splice(keyElimina,registrosElimina);
      }
   
    return existeValor;
  }

  exportarPdf(ds: any) {
  


    this.vistaResumenList = [];
    Object.keys(this.dataSource.data).forEach(key => {
      this.vistaResumen = new VistaResumen();
      this.vistaResumen.territorio = this.dataSource.data[key]["territorio"];
      this.vistaResumen.totalActas = this.dataSource.data[key]["totalActas"];
      this.vistaResumen.actasAprobadas = this.dataSource.data[key]["actasAprobadas"];
      this.vistaResumen.porcentaje = this.dataSource.data[key]["porcentaje"];
      this.vistaResumen.noIniciado = this.dataSource.data[key]["noIniciado"];
      this.vistaResumen.enProceso = this.dataSource.data[key]["enProceso"];
      this.vistaResumen.atrasado = this.dataSource.data[key]["atrasado"];

      this.vistaResumenList.push(this.vistaResumen);
    });
    this.vistaResumenExport= new VistaResumenExport();
    this.vistaResumenExport.idProceso=this.idProceso;
    this.vistaResumenExport.vistaResumen=this.vistaResumenList;
    this.vistaExport= new VistaExport();
    this.vistaExport.vistaResumenExport=this.vistaResumenExport;
    this.vistaExport.informeEnum=InformeEnum.INFORME_RESUMEN;


    
    this.documentoService.generatePdf(this.vistaExport).subscribe((resp) => {
      if (resp.body['body']['response']) {
        
        this.utils.downloadFile({
          name: resp.body['body']['data']['nombreReporte'],
          file64: resp.body['body']['data']['base64'],
          mimetype: "application/pdf"
        })

      }

    });
    //console.log(JSON.stringify(this.dataSource.data[0]["continente"]));
    //this.vistaSupervisor.continente=this.dataSource[]


  }
}
