import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
primerLogin:boolean=false;

  constructor(public formBuilder: FormBuilder,private loginService: LoginService,private utils: UtilsService) { }

/*   public loginForm: FormGroup;
  error= false;
  constructor(public formBuilder: FormBuilder,private loginService: LoginService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      usuario: ['', Validators.required],
      clave: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  login() {
    if (this.loginForm.valid) {
      localStorage.setItem("valida_token","false");
      this.loginService.loginAutenticate(this.loginForm.value.usuario,this.loginForm.value.clave,true);
    }
  }  */
  @Input() error: string | null=null;

  @Output() submitEM = new EventEmitter();
   ngOnInit() {
    this.loginService.redirectUser();
   }
  form: FormGroup = new FormGroup({
    rut: new FormControl(''),
    password: new FormControl(''),
    passwordNew: new FormControl(''),
    passwordNew2: new FormControl('')
  });

  submit() {
    if (this.form.valid) {
      this.submitEM.emit(this.form.value);
    }
  }
  login() {
   
    if (this.form.valid) {
      localStorage.setItem("valida_token","false");
      let rutArr= this.form.value.rut.split("-");
      if (this.form.value.passwordNew == this.form.value.passwordNew2){
        this.loginAutenticate(rutArr[0],rutArr[1],this.form.value.password,this.form.value.passwordNew,true);  
      }else{
        this.error= "las contraseñas no son iguales";
      }
      


      
    }

  }


  loginAutenticate(rut: number, dv: string, pass: string,passNew:string, isRedirect: boolean) {
    this.loginService.autenticate(rut, dv, pass,passNew).then((token) => {

      localStorage.setItem("recupera_token", "false");
      this.loginService.evaluaAccesos(token, pass, isRedirect);

    }, (err) => {
      console.log("error: " + JSON.stringify(err));
      if (err.code!=205){
      localStorage.setItem("recupera_token", "false");
      this.utils.mDialog("Error",err.message, "message");
      }else{
        this.primerLogin=true;
      }
    }
    );


    
  
  }
 
}
