
  <h1 class="display-5 title-app">Zona Horaria</h1>
  <div *ngIf="dataSource">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
        <div fxFlex="100%">
            <mat-form-field class="full">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Búsqueda Avanzada">
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end" fxLayoutGap="1%">
        <div fxFlex="20%">
            <div class="btn-section">

                <button mat-button mat-raised-button class="bg-green boton-vista-exp" (click)="exportarExcel(dataSource)">Exportar Excel</button>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="continente" >
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-short">Continente</mat-header-cell>
      <mat-cell *matCellDef="let row" class="mat-column-short"> {{row.continente}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="pais">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-short">Pais</mat-header-cell>
      <mat-cell *matCellDef="let row" class="mat-column-short"> {{row.pais}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="juntaElectoral">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-other">Junta</mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-other"> {{row.juntaElectoral}} </mat-cell>
      </ng-container>
          <ng-container matColumnDef="circunscripcionElectoral">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-other">Circunscripcion Electoral</mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-other"> {{row.circunscripcionElectoral}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="localVotacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-other">Local Votación</mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-other"> {{row.localVotacion}} </mat-cell>
      </ng-container>
    

    <ng-container matColumnDef="zonaHoraria" >
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-timezone">Zona Horaria</mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-timezone">{{row.zonaHoraria}}</mat-cell>
      </ng-container>

    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef class="mat-column-short"> Acciones </mat-header-cell>
      <mat-cell *matCellDef="let val" data-label="Acciones" class="mat-column-short">
        <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Haga click para ver opciones" matTooltipPosition="right">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="crearItem(val, 'edit')" >
            <mat-icon>create</mat-icon>
            <span>Editar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div *ngIf="length == 0" class="noresults">No se encontraron resultados</div>
  <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons>
  </mat-paginator>
</div>
</div>