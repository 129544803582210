import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ActaService {

  constructor(    private http: HttpClient) { }

    
  public vistaConsul(codigoLocal:number) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/vistaconsul/codigoLocal/" + codigoLocal,{observe:'response'});
  }

  public actualizaActa(acta: any  ) {
    return this.http.put<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/acta", acta,{observe:'response'});
  }

  public vistaConsulTitulo(codigoLocal:number) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/vistaconsul/nombre/local/" + codigoLocal,{observe:'response'});
  }

  public vistaAdministrador(codigoProceso:number) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/vistasupervisor/proceso/" + codigoProceso,{observe:'response'});
  }

  
  public cuentaRegresiva(codigoLocal:number) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/acta/alerta/local/" + codigoLocal,{observe:'response'});
  }

  public cuentaRegresivaAll() {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/acta/alerta/local",{observe:'response'});
  }
  public getResumenActa(tipoNivel:string,idNivel:number,idProceso:number) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/actaRestService/acta/resumen/tipoNivel/" + tipoNivel + "/nivel/" + idNivel + "/proceso/" + idProceso,{observe:'response'});
  }

}
