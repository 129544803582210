import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TerritorioService {

  constructor( private http: HttpClient) { }

  public getTerritorioRutTipo(rut:string, tipo:string) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/territorioRestService/territorio/rut/" + rut + "/tipo/" + tipo,{observe:'response'});
    
  }


  
  public getTerritoriosTipo(tipo:string) {
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/territorioRestService/territorio/tipo/" + tipo,{observe:'response'});
    
  }

  getTerritorioByIdTipo(idTerritorio:number,tipoPadre:string,tipoHijo:string){
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/territorioRestService/territorio/relTerritorio/" + idTerritorio + "/tipoPadre/"+tipoPadre + "/tipoHijo/" + tipoHijo,{observe:'response'});
  } 

  getTerritorioTimezones(){
    return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/territorioRestService/territorio/timeZones",{observe:'response'});
  } 

  getTerritorioLocales(){
   return this.http.get<HttpResponse<Object>>(environment.baseApi+"/api/private/rlcTerritorioRestService/rlcTerritorio/territorio/locales",{observe:'response'});   
  } 

  updateTerritorio(territorio: any){
    return this.http.put<HttpResponse<Object>>(environment.baseApi+"/api/private/territorioRestService/territorio", territorio,{observe:'response'});
  }
}
