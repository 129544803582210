import { FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Item } from 'src/app/models/Item';
import { alertaProcesoActa } from 'src/app/component/enums/alerta-proceso-acta.enum';

@Component({
  selector: 'app-filtro-alerta-acta',
  templateUrl: './filtro-alerta-acta.component.html',
  styleUrls: ['./filtro-alerta-acta.component.css']
})
export class FiltroAlertaActaComponent implements OnInit {
  dtAlertas: any=[];
  itemAlerta:any;
  item: Item;
  dtAlertasFiltro: any = [];
  @Output() public filtroEstadoAlertaOutput: EventEmitter<any> = new EventEmitter();
  itemControl = new FormControl();
  constructor() { }

  ngOnInit(): void {
    this.getAlertaActa();
  }
  getAlertaActa() {
    this.dtAlertas= [];
    Object.keys(alertaProcesoActa).forEach(key => {
      this.item = new Item();
      this.item.codigo=key.toString();
      this.item.nombre=alertaProcesoActa[key];
      this.dtAlertas.push(this.item);
     
    }
    )

   
    /* return estadoActa[estado]; */
  }

  setEstadoAlerta(event: any, val: any) {
    if (event.checked) {
      this.dtAlertasFiltro.push(val.codigo);
    } else {
      this.dtAlertasFiltro.splice(val.codigo, 1);
    }

    this.filtroEstadoAlertaOutput.emit(this.dtAlertasFiltro);
  }

  limpiar(){
    this.itemControl.setValue(false);
    this.dtAlertasFiltro=[];
  }
}
