export enum TextoBusquedaEnum{
    CODIGO_CONTINENTE="codigocontinente",
    CODIGO_PAIS="codigopais",
    CODIGO_JUNTA="codigojunta",
    CODIGO_CIRCUNSCRIPCION="codigocircunscripcion",
    CODIGO_LOCAL="codigolocal",
    CODIGO_ESTADO_ACTA="estadoacta",
    CODIGO_ALERTA_ACTA="alertaacta",
    CODIGO_TEXTO="texto"
}
