

<div [formGroup]="territorios">
    <div fxFlex="80%" [ngClass]="(limpiaFiltros)?'btn-section-filtro': ''">
            <div class="titulo-vista">
                    Filtros Territoriales
                </div>
        <mat-form-field class="full">
                <mat-label>Continente</mat-label>
            <mat-select matTooltip="Seleccione un continente" [(ngModel)]="itemContinente" formControlName="continente" (selectionChange)="getTerritorio(itemContinente.id,itemContinente.tipo,'PAIS')">
                <mat-option *ngFor="let item of dtContinentes" [value]="item">{{item.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
   <!--  </div>
    <div fxFlex="40%"> -->
        <mat-form-field class="full">
                <mat-label>Pais</mat-label>
            <mat-select matTooltip="Seleccione un pais" [(ngModel)]="itemPais" formControlName="pais"(selectionChange)="getTerritorio(itemPais.idTerritorio,itemPais.tipo,'JUNTA')">
                <mat-option *ngFor="let item of dtPais" [value]="item">{{item.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
   <!--  </div>
    <div fxFlex="40%"> -->
        <mat-form-field class="full">
                <mat-label>Junta Electoral</mat-label>
            <mat-select matTooltip="Seleccione una junta electoral" [(ngModel)]="itemJunta" formControlName="junta" (selectionChange)="getTerritorio(itemJunta.idTerritorio,itemJunta.tipo,'CIRCUNS')">
                <mat-option *ngFor="let item of dtJunta" [value]="item">{{item.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
    <!-- </div>

    <div fxFlex="40%"> -->
        <mat-form-field class="full">
                <mat-label>Circunscripción</mat-label>
            <mat-select matTooltip="Seleccione una circunscripción" [(ngModel)]="itemCircunscripcion" formControlName="circunscripcion" (selectionChange)="getTerritorio(itemCircunscripcion.idTerritorio,itemCircunscripcion.tipo,'LOCAL')">
                <mat-option *ngFor="let item of dtCircunscripcion" [value]="item">{{item.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
   <!--  </div>

    <div fxFlex="40%"> -->
        <mat-form-field class="full">
                <mat-label>Local de Votación</mat-label>
            <mat-select matTooltip="Seleccione un local" [(ngModel)]="itemLocalVotacion" formControlName="localVotacion" (selectionChange)="getTerritorio(itemLocalVotacion.idTerritorio,itemLocalVotacion.tipo,'LOCAL')">
                <mat-option *ngFor="let item of dtLocal" [value]="item">{{item.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="limpiaFiltros">
        <button mat-button mat-raised-button class="bg-blue" (click)="limpiar()" >Limpiar filtros</button>
        </div>
    </div>
</div>