<div fxLayout="row wrap" fxLayout.xs="column" *ngIf="dataSourceIns" fxLayoutGap="1%">
    <div [formGroup]="territorios" style="width: 100%">
        <div fxFlex="100%">
            <div class="btn-section titulo-vista">
                <mat-form-field class="width-pais" appearance="fill">
                    <mat-label>País</mat-label>
                    <input matInput formControlName="ctlPais">
                </mat-form-field>/
                <mat-form-field class="width-junta" appearance="fill">
                    <mat-label>Junta Electoral</mat-label>
                    <input matInput formControlName="ctlJunta">
                </mat-form-field>/
                <mat-form-field class="width-circunscripcion" appearance="fill">
                    <mat-label>Circunscripción Electoral</mat-label>
                    <mat-select matTooltip="Seleccione una circunscripción" [(ngModel)]="itemCircunscripcion"
                        (selectionChange)="changeCircunscripcion()" formControlName="ctlCircunscripcion">
                        <mat-option *ngFor="let item of dtCircunscripcion" [value]="item">{{item.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="width-local" appearance="fill">
                    <mat-label>Local de Votación</mat-label>
                    <mat-select matTooltip="Seleccione un local de votación" [(ngModel)]="itemLocalVotacion"
                        (selectionChange)="getData()" formControlName="localVotacion">
                        <mat-option *ngFor="let item of dtLocalVotacion" [value]="item">{{item.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dataSourceIns && this.mapaAlertaFinal">

    <div class="btn-section titulos-tablas">
        Constitución e Instalación de Mesas
    </div>
    <div class="mat-elevation-z8">
        <mat-table [dataSource]="dataSourceIns" matSort>
            <ng-container matColumnDef="proceso">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Proceso</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.proceso}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="estadoacta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Estado Acta</mat-header-cell>
                <mat-cell *matCellDef="let row"
                    [ngClass]="{'color_rechazada': row.estadoActa=='RECHAZADA'}">{{getEstadoActa(row.estadoActa)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cumplimientohito">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Cumplimiento Hito</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.fechaCumplimientoHito| date:'dd/MM/yyyy HH:mm'}} </mat-cell>

            </ng-container>

            <ng-container matColumnDef="alertaenvioacta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Alerta Envío Acta</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div
                        *ngIf="row.estadoActa != 'APROBADA'  && this.mapaAlertaFinal.get(row.codigoLocalVotacion)!=undefined">
                        <app-count-down [idProceso]="row.codigoProceso" [codigoLocal]="row.codigoLocalVotacion"
                            [estadoAlerta]="row.alertaEnvio"
                            [minutesToDday]="this.mapaAlertaFinal.get(row.codigoProceso).minutesToDday"
                            [hoursToDday]="this.mapaAlertaFinal.get(row.codigoProceso).hoursToDday"
                            [secondsToDday]="this.mapaAlertaFinal.get(row.codigoProceso).secondsToDday"></app-count-down>
                    </div>
                    <div *ngIf="row.estadoActa == 'APROBADA'">
                        <i class="fas fa-check-double fa-lg fa-fw"></i>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="adjuntaracta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Adjuntar Acta</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <span *ngIf="row.documento==null || row.estadoActa == 'RECHAZADA'">
                        <i class="fas fa-cloud-upload-alt fa-lg fa-fw"
                            *ngIf="row.documento==null || row.estadoActa == 'RECHAZADA'" (click)="crearItem(row)"></i>
                    </span>
                    <span *ngIf="row.documento!=null && row.estadoActa != 'RECHAZADA'">
                        <a (click)="getFile(row.documento.linkExterno)" target="_blank">
                            <i class="far fa-file-pdf fa-3x"></i>
                        </a>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="verrechazo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Rechazo</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="row.estadoActa=='RECHAZADA'">
                        <i class="far fa-eye fa-lg fa-fw" (click)="showRechazo(row)"></i>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsIns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsIns;"></mat-row>
        </mat-table>
        <div *ngIf="lengthIns == 0" class="noresults">No se encontraron resultados</div>
    </div>
</div>

<div *ngIf="dataSourceEsc">
    <div class="btn-section titulos-tablas">
        Escrutinio de Mesas
    </div>
    <div class="mat-elevation-z8">
        <mat-table [dataSource]="dataSourceEsc" matSort>
            <ng-container matColumnDef="mesa">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Mesa</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.mesa}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="estadoacta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Estado Acta</mat-header-cell>
                <mat-cell *matCellDef="let row"
                    [ngClass]="{'color_rechazada': row.estadoActa=='RECHAZADA'}">{{getEstadoActa(row.estadoActa)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cierremesa">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Cierre Mesa</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.cierreMesa | date:'dd/MM/yyyy HH:mm'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="alertaenvioacta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Alerta Envío Acta</mat-header-cell>
                <mat-cell *matCellDef="let row">

                    <div
                        *ngIf="row.estadoActa != 'APROBADA'  && this.mapaAlertaFinal.get(this.itemLocalVotacion['idTerritorio'])!=undefined">
                        <app-count-down [idProceso]="row.codigoProceso"
                            [codigoLocal]="this.itemLocalVotacion['idTerritorio']" [estadoAlerta]="row.alertaEnvio"
                            [minutesToDday]="this.mapaAlertaFinal.get(this.itemLocalVotacion['idTerritorio']).minutesToDday"
                            [hoursToDday]="this.mapaAlertaFinal.get(this.itemLocalVotacion['idTerritorio']).hoursToDday"
                            [secondsToDday]="this.mapaAlertaFinal.get(this.itemLocalVotacion['idTerritorio']).secondsToDday"></app-count-down>
                    </div>
                    <div *ngIf="row.estadoActa == 'APROBADA'">
                        <i class="fas fa-check-double fa-lg fa-fw"></i>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="adjuntaracta">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Adjuntar Acta</mat-header-cell>
                <mat-cell *matCellDef="let row">

                    <span
                        *ngIf="(row.documento==null || row.estadoActa == 'RECHAZADA') && row.estadoActa !='NO_INSTALADA'">
                        <i class="fas fa-cloud-upload-alt fa-lg fa-fw" (click)="crearItem(row)"></i>
                    </span>
                    <span *ngIf="row.documento!=null && row.estadoActa != 'RECHAZADA'">
                        <a (click)="getFile(row.documento.linkExterno)" target="_blank">
                            <i class="far fa-file-pdf fa-3x"></i>
                        </a>
                    </span>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="verrechazo">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Rechazo</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="row.motivoRechazo!=null">
                        <i class="far fa-eye fa-lg fa-fw" (click)="showRechazo(row)"></i>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsEsc"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsEsc;"></mat-row>
        </mat-table>
        <div *ngIf="lengthEsc == 0" class="noresults">No se encontraron resultados</div>
    </div>
</div>