import { UtilsService } from './../../../services/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActaService } from '../../../services/acta.service';

@Component({
  selector: 'app-pais-resumen',
  templateUrl: './pais-resumen.component.html',
  styleUrls: ['./pais-resumen.component.css']
})
export class PaisResumenComponent implements OnInit {
    constructor() {

   }

  ngOnInit(): void {
  
  }


}
