import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CreaUsuarioComponent } from 'src/app/pages/usuarios/crea-usuario/crea-usuario.component';
import { TerritorioUsuarioComponent } from 'src/app/pages/usuarios/territorio-usuario/territorio-usuario.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  public nombre = new FormControl('', [Validators.required]);
  public verTerritorios: boolean=false;
  constructor(private usuariosService:UsuariosService,public utils: UtilsService,public dialog: MatDialog,public auth:AuthService) {

   }
  public displayedColumns: string[] = ['rut','nombre','pais','circunscripcion','habilitado','perfil','acciones'];
  public dataSource:MatTableDataSource<any>;
  public length:number = 0;
  eleccion:any;
  data: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("territorioUsuario") territorioUsuarioForm: TerritorioUsuarioComponent;
  ngOnInit() {
    let authInfo = this.auth.getAuthInfo();
  this.getData();
  }

  getData(){
    this.usuariosService.getUsuarios().subscribe(data =>{
    
      //this.paises= resp.data;
      
      let dt:any = (data.body['body']['data']) ? data.body['body']['data'] : [];
      //console.log("datos body: " + JSON.stringify(data['body']['data']));
      this.dataSource = new MatTableDataSource(dt);
      //console.log("datos body2: " + JSON.stringify(this.dataSource));
      this.length = dt.length;

      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') { return data[sortHeaderId].toLocaleLowerCase(); }
        return data[sortHeaderId];
      };
       
        setTimeout( () =>{
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },500)
      });

  }

  crearItem(data: any) {
   
    let dialogRef = this.dialog.open(CreaUsuarioComponent, {
      width: '600px',
      data: { data: data }
    });

    dialogRef.componentInstance.dialogEvent.subscribe((result) => {
      if (result) {
        this.utils.mDialog("Notificación", "Datos guardados correctamente", "message");
        this.getData();
      }
    });

  }

  editarTerritorio(data: any) {
   
    this.data=data;
    this.verTerritorios=true;
    setTimeout(() => {
      this.territorioUsuarioForm.closeForm.subscribe((resp) => {
        /* console.log("dataform::" + JSON.stringify(this.dataForm)); */
        this.getData();
        this.verTerritorios=false;
      })
    }, 500)
  

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  muestraSiNo(val:any){
    if (val){
      return 'Si';
    }else{
      return 'No';
    }
  }


}
