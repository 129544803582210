<!-- <p> -->
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1%">
        <div fxFlex="100%">
<div class="fondo">
    <img style="width:100%;height: 100%;" src="./assets/images/home.png">
    <div class="text-block">
        <div style="font-weight: bold">Bienvenidos Sistema Recepción Datos extranjero</div>
        <div>El Sistema ofrece la recepción de formularios sobre los Procesos de Constitución, Instalación y Escrutinio de Mesas
            enviados desde los Locales de Votación del extranjero, para lo anterior, se define lo siguiente:
            <ul>
                <li>Cónsul: encargado de adjuntar los formularios correspondiente a su Local de votación en los tiempos establecidos.</li>
                <li>Supervisor Servel: encargado de gestionar la recepción de formularios, en donde debe revisar los formularios recibidos e informar su rechazo en el caso que el formulario sea incorrecto a través del Sistema.</li>
            </ul>
        </div>
    </div>
</div>
</div>
<div>versión 1.0.9</div>
</div>
<!-- </p> -->