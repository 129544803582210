import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public error: EventEmitter<any> = new EventEmitter<any>();

  errorNotificacion(mensaje?: string, statusCode?: number) {
    this.error.emit({ mensaje: mensaje, statusCode: statusCode });
  }

}
