<h2 mat-dialog-title>Datos del Usuario</h2>
<mat-dialog-content>


        
      
      
      
            
            <app-filtros-territoriales [limpiaFiltros]="false" [territorioData]="this.data"  #filtroTerritorial></app-filtros-territoriales>
        
      



</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button class="bg-blue" [mat-dialog-close]="true" id="cancelar">Cancelar</button>
    <button mat-button mat-raised-button class="bg-red" (click)="guardar()" [disabled]="!validaFiltro">Guardar</button>
</mat-dialog-actions>