import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escrutinio-mesas',
  templateUrl: './escrutinio-mesas.component.html',
  styleUrls: ['./escrutinio-mesas.component.css']
})
export class EscrutinioMesasComponent implements OnInit {
idProceso: number;
titulo: string;
  constructor() { }

  ngOnInit(): void {
    this.idProceso=3;
    this.titulo="Escrutinio de Mesas";
  }

}
